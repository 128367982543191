@import '../../global.scss';

.art-carousel {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	.art-carousel-title {
		font-size: 2rem;
		color: var(--wtob);
		text-transform: uppercase;
		margin-bottom: 1rem;
	}
	.art-carousel-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #000000;
		width: 100%;
		.art-carousel-items {
			display: flex;
			flex-direction: row;
			// justify-content: space-between;
			align-items: center;
			width: fit-content;
			padding-right: 1rem;
			.art-carousel-item {
				width: 14rem;
				height: 14rem;
				@include mp {
					width: 13rem;
					height: 13rem;
				}
				background-size: contain;
				border-radius: 1rem;
				position: relative;
				margin-left: 1rem;

				background-repeat: no-repeat;
				background-position: center;
				background-color: #FFFFFF;
				.art-carousel-item-desc {
					background-color: #9EDA93;
					width: 100%;
					min-height: 15%;
					max-height: 20%;
					position: absolute;
					left: 0rem;
					top: 0rem;
					color: #000000;
					font-size: 1rem;
					border-top-left-radius: 1rem;
					border-top-right-radius: 1rem;
					padding-left: 0.7rem;
					padding-right: 0.7rem;
					box-sizing: border-box;
					opacity: 0.7;
					.art-carousel-item-desc-name {
						text-align: left;
					}
					.art-carousel-item-desc-price {
						text-align: right;
					}
				}
				.art-carousel-item-price {
					background-color: #9EDA93;
					width: 70%;
					height: 15%;
					position: absolute;
					left: 0rem;
					bottom: 0rem;
					color: #000000;
					font-size: 1rem;
					border-top-right-radius: 1rem;
					border-bottom-left-radius: 1rem;
					padding-left: 0.7rem;
					padding-right: 0.7rem;
					box-sizing: border-box;

					display: flex;
					flex-direction: row;
					opacity: 0.7;
					.art-carousel-item-price-r {
						font-size: 1.7rem;
					}
					.art-carousel-item-price-k {
						font-size: 1rem;
					}
					.art-carousel-item-pricep {
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						align-self: flex-end;
						color: #5C5C5C;
						position: relative;
						.art-carousel-item-pricep-r {
							font-size: 1rem;
						}
						.art-carousel-item-pricep-k {
							font-size: 0.6rem;
						}
						svg {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0px;
							left: 0px;
							z-index: 100;
							stroke-width: 0.2rem;
						}
					}
				}
			}
		}
	}
	.carousel-right,.carousel-left {
		color: var(--c);
	}

}