.manufacture {
	display: flex;
	flex-direction: column;
	.manufacture-desc {
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		a {
			text-decoration: none;
		}
		.manufacture-desc-title {
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 2.25rem;
			line-height: 2.625rem;

			color: #9EDA93;
		}
		.manufacture-desc-text {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 2rem;
			line-height: 2.34375rem;
			text-align: center;

			color: var(--c);
		}
	}
	.manufacture-shop {
		background: #9EDA93;
		border-radius: 1rem;
		width: 13.5rem;
		height: 2.093rem;
		line-height: 2.093rem;
		text-align: center;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 1.125rem;

		color: var(--bg);
		margin-top: 0.65rem;
		cursor: pointer;
	}
	.manufacture-title {
		margin-top: 1.9375rem;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 2.25rem;
		line-height: 2.625rem;
		text-align: left;

		color: var(--c);
	}
	.manufacture-image {
		margin-top: 1.84375rem;
		background-image: url('../assets/images/manufacture_main.png');
		width: 100%;
		height: 43rem;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.manufacture-details {
		margin-top: 5.6525rem;
		// margin-left: 1rem;
		margin-left: auto;
		margin-right: auto;
		background-image: url('../assets/images/manufacture_details.png');
		width: calc(100% - 2rem);
		height: 30vh;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.carousel {
		margin-top: 2.625rem;
	}
}