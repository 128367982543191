@import '../../global.scss';
	
.option-link {
	margin-top: 2.4125rem;
	position: relative;
	width: 33.3%;
	@include mp {
		width: 50%;
	}
	&-4 {
		width: 25%;
		@include mp {
			width: 49%;
		}
	}
	.option-space {
		margin-top: 100%;
	}
	.option {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		// width: 100%;
		// height: 0;
		// padding-bottom: 33%;
		// width: calc(min(29vw, 390px));
		// height: calc(min(29vw, 390px));
		// @include mp {
		// 	width: calc(min(45vw, 300px));
		// 	height: calc(min(45vw, 300px));
		// }
	
		display: flex;
		justify-content: center;
		align-items: center;
		&-4 {
			// margin-left: .5rem;
			// margin-right: .5rem;
			// width: calc(min(20vw, 285px));
			// height: calc(min(20vw, 285px));
			// @include mp {
			// 	margin-left: 0px;
			// 	margin-right: 0px;
			// 	width: calc(min(45vw, 300px));
			// 	height: calc(min(45vw, 300px));
			// }
			.option-text {
				div {
					font-size: 1.5rem !important;
					@include mp {
						font-size: 2.2rem !important;
					}
				}
			}
			.option-text-2 {
				div {
					font-size: 1.2rem !important;
					@include mp {
						font-size: 1.7rem !important;
					}
				}
			}
		}
		.option-content {
			width: 95%;
			height: 95%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			&:hover, &-big {
				width: 100%;
				height: 100%;
			}
			.option-image {
				background-size: cover;
				background-repeat: no-repeat;
				width: 100%;
				height: 100%;
				border-radius: 10px;
				background-position: center;
				@include mp {
					border-radius: 6px;
				}
			}
			.option-text {
				position: absolute;
				bottom: 0px;
				width: 100%;
				height: 4rem;
				// background: #DDEA73;
				background: var(--fgryeli);
				border-radius: 10px;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				@include mp {
					border-radius: 6px;
					height: 6rem;
				}
				&-green {
					background: #9EDA93;
				}
				&-greenw {
					background: #9EDA93;
					div {
						color: var(--c) !important;
					}
				}
				&-noimage {
					height: 100%;
				}
				div {
					font-weight: 500;
					font-family: Roboto;
					font-style: normal;
					font-size: 1.7rem;
					text-transform: uppercase;
					@include mp {
						font-size: 2.2rem;
						line-height: 2.3rem;
					}
					color: #000000;
				}
				&-greenwhite {
					background: #9EDA93;
					div {
						color: var(--c);
					}
				}
			}
		}
	
	
		.option-why-we { background-image: url('../assets/images/main_page/why_we.png'); }
		.option-store { background-image: url('../assets/images/main_page/store.png'); }
		.option-promotions { background-image: url('../assets/images/main_page/promotions.png'); }
		.option-vacancy { background-image: url('../assets/images/main_page/vacancy.png'); }
		.option-manufacture { background-image: url('../assets/images/main_page/manufacture.png'); }
		.option-cafe { background-image: url('../assets/images/main_page/cafe.png'); }
		.option-own-cabinet { background-image: url('../assets/images/main_page/own_cabinet.png'); }
		.option-bonuses { background-image: url('../assets/images/main_page/bonuses.png'); }
		.option-address { background-image: url('../assets/images/main_page/address.png'); }
		.option-textimage {
			width: 100%;
			height: 3.21875rem;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
		.option-lacafe { 
			background-image: url('../assets/images/main_page/lacafe.svg'); 
		}
	
		.option-cafe1 { background-image: url('../assets/images/cafe1.png'); }
		.option-cafe2 { background-image: url('../assets/images/cafe2.png'); }
		.option-cafe3 { background-image: url('../assets/images/cafe3.png'); }
		.option-cafe4 { background-image: url('../assets/images/cafe4.png'); }
		.option-cafe5 { background-image: url('../assets/images/cafe5.png'); }
		.option-cafe6 { background-image: url('../assets/images/cafe6.png'); }
	
	}
}