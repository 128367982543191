.carousel-container {
	position: relative;
	width: 100%;
	height: 23.75rem;
	.carousel-left, .carousel-right {
		position: absolute;
		top: 0.5rem;
		height: 100%;
		width: 3%;
		justify-content: center;
		z-index: 100;
		display: flex;
		align-items: center;
		&:hover {
			background: black;
			cursor: pointer;
		}
		svg {
			fill: white;
		}
	}
	.carousel-left {
		left: 0;
	}
	.carousel-right {
		right: 0;
	}
}
.img {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	-webkit-transition: opacity 1s ease-in-out;
	-moz-transition: opacity 1s ease-in-out;
	-o-transition: opacity 1s ease-in-out;
	transition: opacity 1s ease-in-out;
	opacity:0;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
}
.opaque {
	opacity:1;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=1);
}
.carousel2-container {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	.arrow-left {
		background-image: url('../assets/images/arrow.svg');
		transform: rotate(180deg);
		height: 7.78rem;
		width: 1.5rem;
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;
	}
	.arrow-right {
		background-image: url('../assets/images/arrow.svg');
		height: 7.78rem;
		width: 1.5rem;
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;
	}
}