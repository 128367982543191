@import '../../global.scss';

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.header-left {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		@include mp {
			align-items: center;
		}
		.menu-button {
			height: 100%;
			display: none;
			@include mp {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-right: 2.5rem;
			}
			&.menu-button-rot {
				transform: rotate(-90deg);
				transition: transform .6s;
			}
			svg {
				rect {
					fill: var(--fgryeli);
				}
			}
		}
		.logo {
			// background: var(--logo);
			background-size: contain;
			background-repeat: no-repeat;
			width: 9.6875rem;
			height: 3.8125rem;
			@include mp {
				width: 15rem;
				height: 6rem;
			}
			&-black {
				background: url('../assets/images/logo_black.svg');
				background-size: contain;
				background-repeat: no-repeat;
			}
			&-white {
				background: url('../assets/images/logo_white.svg');
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		.logo-desc {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 1.5rem;
			line-height: 1.75rem;
			@include mp {
				display: none;
			}
		
			color: var(--c);
			margin-left: 0.75rem;
		}
	}
	.header-right {
		display: flex;
		flex-direction: row;
		color: white;
		align-items: center;
		width: 45%;
		@include mp {
			width: 50%;
		}
		justify-content: space-between;
		.header-icon {
			cursor: pointer;
			background-size: contain;
			background-repeat: no-repeat;
			position: relative;
			display: inline-block;
			.header-icon-text {
				visibility: hidden;
				width: fit-content;
				background-color: black;
				color: #fff;
				text-align: center;
				border-radius: 6px;
				padding: 5px 0;
			  
				/* Position the tooltip */
				position: absolute;
				z-index: 1;

				font-size: 0.7rem;
				top: -1.5rem;
				white-space: nowrap;
				left: 0;
			}
			&:hover .header-icon-text {
				visibility: visible;
			}
		}
		.a-basket {
			text-decoration: none;
		}
		.header-phone-container {
			display: flex;
			flex-direction: column;
			@include mp {
				display: none;
			}
			.header-phone-phone {
				font-family: Roboto;
				font-weight: normal;
				font-size: 1.5rem;
				line-height: 1.75rem;

				color: var(--c);
				a {
					color: var(--c);
					text-decoration: none;
				}
			}
			.header-phone-time {
				font-family: Roboto;
				font-weight: normal;
				font-size: 0.875rem;
				line-height: 1rem;

				color: var(--c);
			}
			.header-phone-support {
				font-family: Roboto;
				font-weight: normal;
				font-size: 0.7rem;
				line-height: 0.8rem;

				color: var(--c);
			}
		}
		.header-app {
			font-size: 0.93rem;
			font-weight: 500;

			background-color: var(--yeldtol);
			border-radius: 10px;
			padding: 0.875rem 0.75rem;
			width: max-content;
			cursor: pointer;
			@include mp {
				display: none;
			}
			a {
				color: #000000;
				text-decoration: none;
			}
		}
		.header-mobile {
			@extend .header-icon;
			background-image: url('../assets/images/mobile.svg');
			width: 1.5625rem;
			height: 2.0625rem;
			background-position: center;
			filter: var(--inv);
			@include mp {
				width: 3rem;
				height: 3.2rem;
			}
		}
		.header-delivery {
			@extend .header-icon;
			background-image: url('../assets/images/delivery.svg');
			width: 2.5rem;
			height: 2.0625rem;
			background-position: center;
			filter: var(--inv);
			@include mp {
				width: 3rem;
				height: 3.2rem;
			}
		}
		.header-location {
			@extend .header-icon;
			background-image: url('../assets/images/location.svg');
			width: 1.5625rem;
			height: 2.0625rem;
			background-position: center;
			filter: var(--inv);
			@include mp {
				width: 3rem;
				height: 3.2rem;
			}
		}
		.header-favorites {
			position: relative;
			@extend .header-icon;
			background-image: url('../assets/images/favorites.svg');
			width: 1.75rem;
			height: 1.5625rem;
			background-position: center;
			filter: var(--inv);
			@include mp {
				width: 3rem;
				height: 4.2rem;
			}
			// .header-cnt {
			// 	bottom: 0px;
			// 	right: 0px;
			// }
		}
		.header-basket {
			@extend .header-icon;
			position: relative;
			background-image: url('../assets/images/basket.svg');
			width: 1.875rem;
			height: 1.625rem;
			background-position: center;
			filter: var(--inv);
			@include mp {
				width: 3rem;
				height: 4.2rem;
				text-decoration: none;
			}
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-start;
			text-decoration: none;
		}
		.header-cnt-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			position: absolute;
			right: -7px;
			top: -3px;
			@include mp {
				right: -4px;
				top: 0px;
			}
			filter: var(--inv);
			.header-cnt {
				position: absolute;
				// background-color: var(--gtor);
				filter: var(--inv);
				color: var(--wtob);
				border-radius: 0.5rem;
				width: fit-content;
				// padding: 0px 5px;
				// margin-top: -3px;
				// margin-right: -7px;
				font-weight: 900;
				@include mp {
					font-size: 2rem;
					border-radius: 2rem;
					// padding: 0px 2px;
					// margin-top: 0px;
					// margin-right: -4px;
					font-weight: 900;
				}
			}
		}
		.header-phone {
			@extend .header-icon;
			background-image: url('../assets/images/phone.svg');
			width: 1.8125rem;
			height: 1.75rem;
			background-position: center;
			filter: var(--inv);
			@include mp {
				width: 3rem;
				height: 4.2rem;
			}
		}
	}
}