@import '../../global.scss';
.main {
	display: flex;
    flex-direction: column;
	justify-content: space-between;
	align-items: center;
    min-height: 100vh;
}
.main-white {
	background-color: var(--bg);
	color: var(--c);
}
.main-container {
	padding: 2.3125rem 4.3125rem 0px 4.3125rem;
	max-width: 1280px;
	width: calc(min(100vw, 1280px) - 4.3125rem);
	&-blur {
		filter: blur(5px);
	}
}
@keyframes zframe {
	0%   { transform: rotate(0deg); }
	10%  { transform: rotate(-10deg); }
	20%   { transform: rotate(+10deg); }
	30%  { transform: rotate(-10deg); }
	40%   { transform: rotate(+10deg); }
	50%  { transform: rotate(-10deg); }
	60%   { transform: rotate(+10deg); }
	70%  { transform: rotate(-10deg); }
	80%   { transform: rotate(+10deg); }
	40%   { transform: rotate(-10deg); }
	100%  { transform: rotate(0deg); }
}
@keyframes zebra12 {
	0% { background: url('../assets/images/zebra1.png'); }
	50% { background: url('../assets/images/zebra2.png'); }
	100% { background: url('../assets/images/zebra1.png'); }
}
@keyframes z0 {
	from, 50% { background-position: left;}
	50.00001%, to { background-position: right;}
}
@keyframes z1 {
	from, 50% { background-position: top;}
	50.00001%, to { background-position: bottom;}
}
@keyframes z2 {
	from, 50% { background-position: right;}
	50.00001%, to { background-position: left;}
}
@keyframes z3 {
	from, 50% { background-position: bottom;}
	50.00001%, to { background-position: top;}
}
.zebrah {
	z-index: 99999;
	position: fixed;
	width: 150px;height: 225px;
	// top: 0px;
	display: block;
	// &-off {
	// 	left: -100px
	// 	// left: 0px
	// }
	// &-on {
	// 	left: 0px;
	// 	// animation-name: zframe;
	// 	// animation-duration: 1s;
	// 	// animation-iteration-count: infinite;
	// }
	@include mp {
		display: none;
	}
	&-left {
		transition: left 1s linear;
	}
	&-right {
		transition: right 1s linear;
	}
	.zebra1 {
		position: absolute;
		top: 0px;
		left: 0px;
		background: url('../assets/images/zebra12.png');
		background-repeat: no-repeat;
		background-size: 300px 225px;
		width: 149px;
		height: 225px;
		animation-duration: 0.5s;
		animation-iteration-count: infinite;
		z-index: 1100;
	}
}
.zebrav {
	position: fixed;
	width: 150px;height: 100px;
	display: block;
	transition: left 1s linear;
	.zebra1 {
		position: absolute;
		top: 0px;
		left: 0px;
		background: url('../assets/images/zebra12.png');
		background-repeat: no-repeat;
		background-size: 200px 150px;
		width: 100px;
		height: 150px;
		animation-duration: 0.5s;
		animation-iteration-count: infinite;
	}
}
.zebra-an0 {animation-name: z0;}
.zebra-an1 {animation-name: z1; }
.zebra-an2 {animation-name: z2;}
.zebra-an3 {animation-name: z3; }

.main-footer {
	
	background-size: cover;
	background-repeat: no-repeat;
	bottom: 0px;
	right: 0px;
	height: 23.125rem;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	&-black {
		background-image: url('../assets//images/footer-back.png');
	}
	&-white {
		background-image: url('../assets//images/footer-back-white.png');
	}
	.main-footer-row {
		width: calc(min(100vw, 1280px) - 4.3125rem);
		max-width: 1280px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		color: white;
		// margin-top: -80px;
		padding: 8.125rem 4.3125rem 0px 4.3125rem;
		@include mp {
			flex-direction: column;
			font-size: 2.6rem;
			padding-top: 2.4rem;
		}
		.main-footer-row-left, .main-footer-row-right {
			display: flex;			
			flex-direction: row;
			align-items: center;
			color: var(--c);
		}
		.main-footer-row-right {
			@include mp {
				display: none;
			}
		}
	}
	.main-footer-row-2 {
		@include mp {
			display: none;
		}
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: calc(min(100vw, 1280px) - 4.3125rem);
		max-width: 1280px;
		color: var(--wtob);
		padding: 19.125rem 4.3125rem 0px 4.3125rem;
	}
	.main-footer-row-2-mob {
		@include mt {
			display: none;
		}
		@include md {
			display: none;
		}
		margin-top: 4.4rem;
		font-size: 2.6rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: var(--wtob);
	}
	.main-footer-inst {
		background-image: url('../assets/images/inst.png');
		width: 2.875rem;
		height: 2.875rem;
		background-repeat: no-repeat;
		background-size: cover;
		margin-left: 15px;
	}
	.main-footer-fb {
		background-image: url('../assets/images/fb.png');
		width: 2.875rem;
		height: 2.875rem;
		background-repeat: no-repeat;
		background-size: cover;
		margin-left: 1rem;
	}
	.main-footer-telegram {
		background-image: url('../assets/images/telegram.png');
		width: 2.875rem;
		height: 2.875rem;
		background-repeat: no-repeat;
		background-size: cover;
		margin-left: 1rem;
	}
	.main-footer-viber {
		background-image: url('../assets/images/viber.png');
		width: 2.875rem;
		height: 2.875rem;
		background-repeat: no-repeat;
		background-size: cover;
		margin-left: 1rem;
	}
	.main-footer-store {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 1.5rem;
		line-height: 1.8rem;
		text-align: center;
		width: 100%;


		color: var(--yeltob);
		position: absolute;
		top: 5rem;
		left: 0;
	}
}
.arrow-top {
	background-color: #9eda93;
	position: fixed;
	bottom: 3rem;
	right: 3rem;
	z-index: 100;
	border-radius: 6rem;
	width: 3rem;
	height: 3rem;
	color: white;
	cursor: pointer;
}