.label {
	color: yellow;
}
.admin-header {
	height: 3rem;
	display: flex;
	align-items: center;
	color: #ffffff;
	font-weight: 500;
	padding: 0rem 1rem;
}
.btn {
	color: black;
	background: #9fda93;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;
	margin-right: 1rem;
	text-align: center;
	justify-content: center;
}
.btn-alone {
	margin-right: 0px;
}
.btn-disabled {
	background-color: gray;
}
.pointer {
	cursor: pointer;
}
.input-field {
	display: flex;
	flex-direction: row;
	align-items: center;
	.input-field-label {
		margin-right: 1rem;
	}
}


.store-cafe {
	display: flex;
	flex-direction: row;
	height: 2rem;
	color: black;

	.store-cafe-el {
		padding: 0.5rem;
		background: linear-gradient(
			0.95deg,
			#afc04e 14.62%,
			#d5e670 45.92%,
			#ddea73 87.42%
		);
		cursor: pointer;
	}
	.store-cafe-active {
		background: #9fda93;
	}
}

.admin-container {
	color: yellow;
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	.admin-header {
		border-bottom: 1px solid yellow;
	}
	.admin-panel {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 100%;
		.admin-menu {
			width: 20%;
            min-width: 20%;
			text-align: left;
			border-right: 1px solid yellow;
			.admin-menu-item {
				font-family: Roboto;
				font-size: 1rem;
				line-height: 2rem;
				font-weight: 300;
				cursor: pointer;
				background: linear-gradient(
					0.95deg,
					#afc04e 14.62%,
					#d5e670 45.92%,
					#ddea73 87.42%
				);
				color: #000000;
				padding: 1rem;
			}
			.admin-menu-item-active {
				background: #9fda93;
				font-weight: 500;
			}
		}
		.admin-body {
			width: 100%;
			height: 100%;
		}
	}
}
input {
	line-height: 1.65rem;
}
.media {
	box-sizing: border-box;
	width: calc(100%);
	height: 100%;
	padding: 2rem;
	.media-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 1rem;
		align-items: flex-end;
		.media-add-col {
			display: flex;
			flex-direction: column;
			width: 70%;
			.media-add-row {
				display: flex;
				flex-direction: row;
				padding-bottom: 1rem;
				justify-content: space-between;
				.media-add-row-name,
				.media-add-row-buttons {
					display: flex;
					flex-direction: row;
					line-height: 2rem;
					input {
						margin-left: 1rem;
					}
				}
			}
		}
		.media-search {
			width: 20%;
			input {
				box-sizing: border-box;
				width: 100%;
				line-height: 1.65rem;
			}
		}
		.media-desc {
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: center;
			input {
				width: 100%;
				margin-left: 1rem;
			}
		}
	}
	.media-table-container {
		height: 90%;
		overflow-y: scroll;
		.media-table {
			color: black;
			border: 1px solid #ffffff;
			width: 100%;
			.cview { width: 20%;}
			.name { width: 30%;}
			.cdesc { width: 50%;}
			th {
				color: white;
				padding: 1rem;
			}
			thead {
				tr {background: black;}
			}
			
			tr {
				background: linear-gradient(
					0.95deg,
					#afc04e 14.62%,
					#d5e670 45.92%,
					#ddea73 87.42%
				);
			}
			tr.active {
				background: #9fda93;
			}
			tr:hover {
				background: #9fda93;
				color: black;
			}
			td {
				padding-top: 1rem;
				padding-bottom: 1rem;
			}
			img {
				max-width: 10rem;
				max-height: 5rem;
			}
		}
	}
}

.banners, .home, .cafe {
	.banner-add, .home-add {
		display: flex;
		flex-direction: row;
		padding: 1rem;
		justify-content: space-between;
		align-items: center;
		.banner-select, .cafe-select {
			width: 80%;
			color: black;
			text-align: left;
			&-2 {
				margin-left: 24px;
				margin-right: 24px;
			}
		}
		.home-select {
			@extend .banner-select;
			width: 30%;
		}
		.home-input {
			height: 2.375rem;
			border-radius: 6px;
		}
		.btn {
			width: 15%;
		}
		.store-cafe {
			margin-left: 1rem;
			margin-right: 1rem;
		}
		.input-field {
			margin-left: 1rem;
			margin-right: 1rem;
		}
		.input-text {
			width: 100%;
			height: 80px;
			textarea {
				width: 100%;
				height: 100%;
			}
		}
	}
	.banners-table-container {
		box-sizing: border-box;
		width: 100%;
		padding: 0rem 1rem;
		.banners-table {
			width: 100%;
			border: 1px solid white;
			thead {
				color: white;
				tr {
					background: black;
				}
				th {
					padding-top: 1rem;
					padding-bottom: 1rem;
				}
				.cactive {width: 10%;}
				.cview {width: 20%;}
				.cname {width: 50%;}
				.cdel {width: 10%;}
				.corder {width: 10%;}
			}
			tr {
				background: linear-gradient(
					0.95deg,
					#afc04e 14.62%,
					#d5e670 45.92%,
					#ddea73 87.42%
				);
				td {
					color: black;
					height: 3rem;
					img {
						max-width: 10rem;
						max-height: 5rem;
					}
				}
				.banner-del {
					cursor: pointer;
				}
			}
			tr.active {
				background: #9fda93;
			}
			tr:hover {
				cursor: pointer;
			}
		}
	}
}
.we {
	height: 100%;
	.jodit-wysiwyg {
		background-color: var(--bg);
	}
	.we-btn {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin: 1rem 0;
	}
	.we-commands {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-left: 1rem;
		padding-right: 1rem;
		.we-select-group {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 2rem;
			color: black;
			margin-top: 1rem;
			margin-bottom: 1rem;
		
			.we-select {
				padding: 0.5rem;
				background: linear-gradient(
					0.95deg,
					#afc04e 14.62%,
					#d5e670 45.92%,
					#ddea73 87.42%
				);
				cursor: pointer;
			}
			.we-select-active {
				background: #9fda93;
			}
		}
	}
}