.switch-container {
	position: relative;
	width: 5rem;
	height: 3rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.switch-labels {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
.switch {
	position: relative;
	display: inline-block;
	width: 2.5rem;
	height: 1.3rem;
	
  }
  
  .switch input { 
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #FFFFFF;
	-webkit-transition: .4s;
	transition: .4s;
	transform: rotate(90deg);
	box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 1.1rem;
	width: 1.1rem;
	left: 0.1rem;
	bottom: 0.1rem;
	background-color: #9EDA93;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: #FCFCFC;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #FCFCFC;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(1.1rem);
	-ms-transform: translateX(1.1rem);
	transform: translateX(1.1rem);
  }

  .slider-label{
	  font-family: Roboto;
	  font-size: 0.875rem;
	  color: #FFFFFF;
	  text-transform: uppercase;
	  color: var(--c);
	//   position: absolute;
	//   right: -2.5rem;
	//   &-black {
	// 	top: -0.8rem;
	// }
	//   &-white {
	// 	bottom: -0.8rem;
	// }
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 1.0625rem;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }
  