.pages {
	display: flex;
	flex-direction: row;
	justify-content: center;
	color: var(--c);
	align-items: center;
	font-size: 1.5rem;
	margin-top: 1rem;
	.pages-number {
		margin-left: 1rem;
		margin-right: 1rem;
	}
	svg {
		cursor: pointer;
	}
}