.product {
	width: 20vw;
	max-width: 16.25rem;
	height: 16.25rem;
	background-repeat: no-repeat;
	background-size: cover;
}

.product1 { background-image: url('../../assets/images/manufacture/product1.png');}
.product2 { background-image: url('../../assets/images/manufacture/product2.png');}
.product3 { background-image: url('../../assets/images/manufacture/product3.jpg');}
.product4 { background-image: url('../../assets/images/manufacture/product4.png');}

.product5 { background-image: url('../../assets/images/manufacture/product5.jpg');}
.product6 { background-image: url('../../assets/images/manufacture/product6.jpg');}
.product7 { background-image: url('../../assets/images/manufacture/product7.jpg');}
.product8 { background-image: url('../../assets/images/manufacture/product8.png');}

