@import '../../global.scss';

@font-face {
	font-family: "Proxima Nova Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Condensed\ Regular\ TheFontsMaster.com.otf');
}
@font-face {
	font-family: "Proxima Nova Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Condensed\ Bold\ TheFontsMaster.com.otf');
	font-weight: 700;
}
@font-face {
	font-family: "Proxima Nova Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Condensed\ Extrabold\ TheFontsMaster.com.otf');
	font-weight: 900;
}
@font-face {
	font-family: "Proxima Nova Extra Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Extra\ Condensed\ Regular\ TheFontsMaster.com.otf');
}
@font-face {
	font-family: "Proxima Nova Extra Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Extra\ Condensed\ Bold\ TheFontsMaster.com.otf');
	font-weight: 700;
}
@font-face {
	font-family: "Proxima Nova Extra Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Extra\ Condensed\ Extrabold\ TheFontsMaster.com.otf');
	font-weight: 900;
}

.questions {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 0px auto 0px auto;
    width: 80%;
    .main-container {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        text-align: left;
    }
}
.q-header, .q-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .q-header-left, .q-wrap-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 60%;
    
    }
    .q-header-right {
        &-b {
            background-image: url('../assets/images/questions/zebra-q-b.png');
        }
        &-w {
            background-image: url('../assets/images/questions/zebra-q.png');
        }
        width: 17rem;
        height: 17rem;
        background-size: contain;
        background-repeat: no-repeat;
        // filter: var(--inv1);
        margin-top: 3rem;
    }
    .q-wrap-right {
        &-b {
            background-image: url('../assets/images/questions/questions-lst-b.png');
        }
        &-w {
            background-image: url('../assets/images/questions/questions-lst.png');
        }
        width: 20rem;
        height: 11rem;
        background-size: contain;
        background-repeat: no-repeat;
        // filter: var(--inv1);
        margin-top: 3rem;
        position: absolute;
        @include mp {
            right: 5rem;
        }
        @include mt {
            right: 15rem;
        }
        @include md {
            right: 15rem;
        }
    }
}
.q-header-title {
    font-family: 'Proxima Nova Condensed';
    font-size: 3.6rem;
    // color: var(--bgr);
    color: #359d78;
    font-weight: 900;
}
.q-title-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3rem;
    .q-title {
        font-family: 'Proxima Nova';
        font-size: 2.2rem;
        color: var(--c);
        font-weight: 700;
        margin-top: 1rem;
    
    }
    .q-text {
        font-family: 'Proxima Nova';
        font-size: 1.4rem;
        color: var(--c);
        font-weight: 700;
        margin-top: 1.5rem;
        text-align: left;
        line-height: 2rem;
        .q-text-emph {
            color: #359d78;
        }
    }
}
.q-addin {
    font-family: 'Proxima Nova';
    font-size: 1.4rem;
    color: #359d78;
    font-weight: 700;
    margin-top: 2rem;
    text-align: left;
}
.q-short {
    width: 90%;
}
.q-lane {
    background-image: url('../assets/images/questions/lane.png');
    width: 100vw;
    /* background-size: contain; */
    background-repeat: no-repeat;
    position: absolute;
    @include mp {
        top: 62rem;
        height: 47rem;
        background-size: 102vw 47rem;
    }
    @include mt {
        top: 53rem;
        height: 42rem;
        background-size: 102vw 42rem;
    }
    @include md {
        top: 53rem;
        height: 42rem;
        background-size: 102vw 42rem;
    }
    left: 0;
    .q-lane-title {
        font-family: 'Proxima Nova';
        font-size: 2.5rem;
        font-weight: 700;
        color: #FFFFFF;
        @include mp {
            width: 90%;
            margin-top: 15rem;
        }
        @include mt {
            width: 80%;
            margin-top: 12rem;
        }
        @include md {
            width: 80%;
            margin-top: 12rem;
        }
        margin-left: auto;
        margin-right: auto;
    }
    ul {
        font-family: 'Proxima Nova';
        font-size: 1.4rem;
        color: #FFFFFF;
        font-weight: 700;
        text-align: left;
        line-height: 2rem;
        padding-left: 2rem;
        @include mp {
            width: 90%;
        }
        @include mt {
            width: 80%;
        }
        @include md {
            width: 80%;
        }
        margin-left: auto;
        margin-right: auto;
        margin-top: 4rem;
    }
}
.q-lane-margin {
    margin-top: 45rem;
}