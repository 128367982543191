@import '../../global.scss';
.breadcrumb {
	text-align: left;
	margin-top: 0.6875rem;
	margin-bottom: 0.6875rem;
	a {
		text-decoration: none;
	}
	@include mp {
		line-height: 3rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}
.breadcrumb-current {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 1.125rem;
	line-height: 1.5rem;

	color: var(--c);
	@include mp {
		font-size: 2.2rem;
	}
}
.breadcrumb-prev {

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 1.125rem;
	line-height: 1.5rem;

	color: #9D9D9D;
	@include mp {
		font-size: 2.2rem;
	}
}