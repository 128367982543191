@import '../../../global.scss';
.banner {
	width: 100%;
	height: 23.75rem;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-top: 0.5rem;
	position: relative;
	z-index: 2;
	@include mp {
		background-size: contain;
	}

	// -webkit-transition: opacity 1s ease-in-out;
	// -moz-transition: opacity 1s ease-in-out;
	// -o-transition: opacity 1s ease-in-out;
	// transition: opacity 1s ease-in-out;
	// opacity: 0;
	// -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	// filter: alpha(opacity=0);
	.banner-text {
		position: absolute;
		top: 2.5rem;
		right: 2.5625rem;
		max-width: 65%;
		color: var(--c);
		text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 3.75rem;
		line-height: 4.375rem;
		text-align: right;
	}
	.banner-button-tmpl {
		position: absolute;
		background: #ddea73;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 3rem;
		line-height: 3.5rem;
		color: white;
		border-radius: 10px;
	}
	.banner-button {
		@extend .banner-button-tmpl;
		bottom: 1.6875rem;
		right: calc(25% - 9.375rem);
		width: 18.75rem;
	}
	.banner-button-left {
		@extend .banner-button-tmpl;
		bottom: 5rem;
		left: calc(25% - 3rem);
		width: 18.75rem;
	}
}

.image1 { background-image: url("../../assets/images/banner1.png"); }
.image2 { background-image: url("../../assets/images/banner2.png"); }
.image3 { background-image: url("../../assets/images/banner3.png"); }
.image4 { background-image: url("../../assets/images/banner4.png"); }
.image5 { background-image: url("../../assets/images/banner5.png"); }
.image6 { background-image: url("../../assets/images/banner6.png"); }
.image7 { background-image: url("../../assets/images/banner7.png"); }
.image8 { background-image: url("../../assets/images/banner8.png"); }
.image9 { background-image: url("../../assets/images/banner9.png"); }
.image10 { background-image: url("../../assets/images/banner10.png"); }
.image11 { background-image: url("../../assets/images/banner11.png"); }
// .opaque {
// 	opacity: 1;
// 	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
// 	filter: alpha(opacity=1);
// }
