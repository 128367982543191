@import '../../global.scss';

.basket-list {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	.basket-item {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-bottom: 1.5rem;
		justify-content: space-between;
		.item-left {
			display: flex;
			flex-direction: row;
			width: 90%;
		}
		.item-img {
			width: 15rem;
			height: 15rem;
			background-position: center;
			border-radius: 1rem;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-color: #FFFFFF;
		}
		.item-details {
			display: flex;
			flex-direction: column;
			margin-left: 2rem;
			width: 70%;
			text-align: left;
			.item-name {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 2rem;
				line-height: 2.34rem;
	
				color: var(--c);
			}
			.item-qty {

				margin-top: 2rem;
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 1.5rem;
				line-height: 1.75rem;
				text-align: left;
				color: var(--yeltob);
				display: flex;
				align-items: center;
				svg {
					margin-left: 0.5rem;
					fill: var(--fgryeli);
					cursor: pointer;
				}
			}
			.item-price {
				margin-top: 1rem;
				justify-content: flex-start;
				color: var(--yeltob);
				display: flex;
				flex-direction: row;
				.article-price-1 {
					font-size: 4rem;
					line-height: 4rem;
				}
				.article-price-2 {
					font-size: 2rem;
					line-height: 2rem;
				}
			}
		}
		svg {
			fill: var(--c);
		}
		.trash {
			font-size: 2rem;
			cursor: pointer;
		}
	}
	.check {
		display: flex;
		flex-direction: row;
		// background-color: var(--wtogrey);
		background-color: #FFFFFF;
		border-radius: 1rem;
		padding: 2rem 6rem;
		text-align: left;
		box-sizing: border-box;
		z-index: 2;
		&-white {
			box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
		}
		@include mp {
			flex-wrap: wrap;
		}
		.wrong-input {
			margin-top: 0.2rem;
			height: 1rem;
			color: #FF1313;
			text-transform: uppercase;
			text-align: center;
			@include mp {
				font-size: 2.8rem;
				height: 4rem;
				white-space: normal;
			}
		}
		.check-left {
			display: flex;
			flex-direction: column;
			width: 60%;
			@include mp {
				width: 100%;
			}
	
			.phone-container {
				width: 40%;
			}
			input {
				box-sizing: border-box;
			}
		}
		.check-right {
			margin-left: 3rem;
			white-space: nowrap;
			@include mp {
				margin-top: 24px;
				font-size: 3rem;
				margin-left: 0rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
			}
			.check-right-label {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 0.75rem;
				line-height: 0.875rem;
				color: #000000;
				margin-bottom: 0.5rem;
				@include mp {
					font-size: 3rem;
					line-height: 3.25rem;
				}
				&-big {
					font-size: 1.5rem;
					line-height: 1.7rem;
					@include mp {
						font-size: 4rem;
						line-height: 4.5rem;
					}
				}
			}
			.check-right-reshta {
				&-hi {
					height: fit-content;
					overflow: visible;
				}
				&-low {
					height: 0px;
					overflow: hidden;
				}
			}
			.pack-container {
				@include mp {
					width: 100%;
				}
			}
			.pack-header {
				margin-top: .5rem;
				display: flex;
				flex-direction: row;
				align-items: center;
				cursor: pointer;
				.pack-label {
					margin-left: .5rem;
				}
			}
			.pack-packs {
				display: flex;
				flex-direction: column;
				justify-content: center;
				.pack-pack {
					margin-top: 0.4rem;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					.pack-pack-cnt {
						display: flex;
						flex-direction: row;
						align-items: center;
						text-align: center;
						color: #9EDA93;
						@include mp {
							justify-content: space-between;
							width: 20%;
						}
						div, svg {
							width: 2rem;
							cursor: pointer;
							@include mp {
								width: 3rem;
							}
						}
						div {
							color: #000000;
							font-weight: 500;
						}
					}
				}
			}
			.btns {
				.wrong-input {
					color: #FF0000;
				}
				@include mp { 
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					font-size: 2.5rem;
				}

			}
			.check-right-meth {
				display: flex;
				.check-right-meth-del, .check-right-meth-pay {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
				.check-right-meth-pay {
					margin-left: 2rem;
				}
			}
		}
		.check-title-container {
			margin-top: 1.5rem;
		}

		.check-title {
			margin-top: 1.5rem;
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 2rem;
			line-height: 2.34rem;
			text-transform: uppercase;
			/* identical to box height */
			@include mp {
				font-size: 3rem;
				line-height: 3.34rem;
			}

			color: #000000;
			&:first-of-type {
				margin-top: 0px;
			}
			&-2 {
				margin-top: 2rem;
				font-size: 1.5rem;
			}
			&-2-w {
				font-size: 1.5rem;
				margin-bottom: 0.5rem;
				@include mp {
					font-size: 2rem;
				}
			}
			&-w {
				margin-top: 0rem;
			}

		}
		.check-text {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 1rem;
			line-height: 1.32rem;
			text-transform: uppercase;
			margin-top: 0.5rem;
			@include mp {
				font-size: 2rem;
				line-height: 2.34rem;
			}

			color: #000000;
		}
		.check-row-dttm {
			@include mp {
				flex-direction: row !important;
			}
			.dt-container {
				margin-left: 1.5rem;
			}
		}
		.check-row {
			margin-top: 1rem;
			display: flex;
			flex-direction: row;
			font-size: 0.75rem;
			align-items: flex-end;
			@include mp {
				flex-direction: column;
			}
			input {
				border-color: var(--bg);
				border-radius: 0.5rem;
				padding-left: 5px;
				padding-right: 5px;

				background: #FFFFFF;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
				border-radius: 2rem;
				border: none;

			}
			.react-datepicker-wrapper {
				width: 100%;
				// margin-left: 24px;
				input {
					width: 100%;
					margin-left: 0px;
					background: #FFFFFF;
					box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
					border-radius: 2rem;
					border: none;

				}
				&:first-of-type {
					margin-left: 0px;
				}
			}
			.phone-container, .input-container {
				@include mp {
					font-size: 3rem;
					width: 100%;
					margin-left: 0px;
					margin-top: 1rem;
				}
			}
			.shop-container {
				width: 40%;
				@include mp {
					font-size: 3rem;
					width: 100%;
					margin-top: 2rem;
				}
				& > div {
					& > div {
						// border: 2px solid #000000;
						border-radius: 2rem;
						font-size: 16px;
						background: #FFFFFF;
						box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
					}
				}
			}
			.formel>div:nth-child(1) {
				height: 2.625rem;
			}
			.formel-label {
				margin-bottom: 0.5rem;
				text-align: center;
				@include mp {
					font-size: 2rem;
					line-height: 2.34rem;
				}
				&-center {
					text-align: center;
					margin-bottom: 0.5rem;
				}
			}
			.formel-select {
				height: 43px !important;
				&>div:first-of-type {
					height: 43px !important;
				}
				&>div {
					border: none;
				}
			}
			.check-row-card {
				margin-top: 0.3rem;
				@include mp {
					font-size: 2rem;
					line-height: 2.34rem;
				}
			}
		}
		.check-row-right {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}
		.check-btn {
			padding: 1.53rem 6rem;
			margin-top: 1rem;
			background-color: #9EDA93;
			border-radius: 1rem;
			cursor: pointer;
			text-align: center;
			@include mp {
				padding: 1.53rem 2rem;
				border-radius: 3rem;
			}
		}
		.check-contacts {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			margin-top: 0.75rem;
			@include mp {
				flex-direction: column;
			}
			&-col {
				flex-direction: column;
				width: fit-content;
				.check-contact {
					margin-bottom: 0.7rem;
					margin-left: 0px !important;
					width: 100% !important;
					@include mp {
						margin-bottom: 1.5rem;
					}
				}
			}
			.check-contact {
				display: flex;
				flex-direction: row;
				align-items: center;
				background: #FFFFFF;
				border: none;
				box-sizing: border-box;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
				border-radius: 2rem;
				padding: 0.5rem 1rem;
				text-align: center;
				font-size: 0.75rem;
				margin-left: 1rem;
				border: 3px solid #FFFFFF;
				width: 30%;
				height: 52px;
				@include mp {
					width: 100% !important;
					margin-left: 0rem;
					box-sizing: border-box;
					margin-top: 1rem;
					font-size: 2rem;
					justify-content: space-between;
					// border-radius: 5rem;
				}
				.check-contact-text {
					width: 90%;
					text-align: center;
				}
				.check-contact-text-disabled {
					color: #999999;
				}
				cursor: pointer;
				&-active {
					border: 3px solid #9eda93;
				}
				&:first-of-type {
					margin-left: 0rem;
				}
			}
		}
		.check-mark {
			margin-top: 1rem;
			font-size: 0.75rem;
			display: flex;
			flex-direction: row;
			align-items: center;
			@include mp {
				font-size: 1.8rem;
			}
			.check-mark-box {

				border: 1px solid #000000;
				height: 1rem;
				width: 1rem;
				min-width: 1rem;
				margin-right: 0.5rem;
				cursor: pointer;
				@include mp {
					border: 1px solid #000000;
					height: 4rem;
					width: 4rem;
					min-width: 4rem;
					margin-right: 1.5rem;
				}
				position: relative;
				div {
					user-select: none;
					transform: scaleX(-1) rotate(-34deg);
					position: absolute;
					font-size: 1.1rem;
					top: -4px;
					left: 4px;
					@include mp {
						font-size: 4.5rem;
						top: -6px;
						left: 6px;
					}
				}
			}
		}
		textarea {
			width: 100%;
			padding: 10px;
			box-sizing: border-box;

			background: #FFFFFF;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
			border-radius: 2rem;
			border: none;

		}
		.check-odd {
			margin-bottom: 1rem;
			width: 50%;
			padding: 8px;
			box-sizing: border-box;

			background: #FFFFFF;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
			border-radius: 2rem;
			border: none;
			@include mp {
				margin-bottom: 2rem;
			}

		}
		.check-telegram {
			background-image: url('../assets/images/telegram.png');
			background-repeat: no-repeat;
			background-size: contain;
			width: 1.7rem;
			min-width: 1.7rem;
			height: 1.5rem;
			margin-left: 0.5rem;
			@include mp {
				width: 5rem;
				min-width: 4rem;
				height: 4rem;
			}
		}
		.check-viber {
			background-image: url('../assets/images/viber.png');
			background-repeat: no-repeat;
			background-size: contain;
			width: 1.8rem;
			min-width: 1.8rem;
			height: 1.7rem;
			margin-left: 0.5rem;
			@include mp {
				width: 5rem;
				min-width: 4rem;
				height: 4rem;
			}
		}
		.check-phone {
			background-image: url('../assets/images/phone.png');
			background-repeat: no-repeat;
			background-size: contain;
			width: 1.7rem;
			min-width: 1.7rem;
			height: 1.5rem;
			margin-left: 0.5rem;
			@include mp {
				width: 5rem;
				min-width: 4rem;
				height: 4rem;
			}
		}
	}
	.favorites-container  {
		margin-top: 2rem;
		.favorites {
			cursor: pointer;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url('../assets/images/favorites.svg');
			width: 1.75rem;
			height: 1.5625rem;
			background-position: center;
			filter: var(--inv);
			@include mp {
				width: 3rem;
				height: 4.2rem;
			}
		}
	}
}
.bill {
	background-color: var(--wtogrey);
	width: fit-content;
	margin: 0px auto;
	border-radius: 1rem;
	z-index: 1;
	.bill-container {
		padding: 1rem 2rem 0rem 2rem;
		z-index: 1;
	}
	.bill-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border-bottom: 1px solid #000000;
		padding: 1rem;
		.bill-info-title {
			font-size: 2rem;
			text-transform: uppercase;
			@include mp {
				font-size: 3rem;
			}
		}
		.bill-info-address {
			margin-top: 0.75rem;
			font-size: 1rem;
			@include mp {
				font-size: 2rem;
			}
		}
		.bill-info-amount {
			font-size: 1.5rem;
			display: flex;
			text-transform: uppercase;
			margin-top: 0.75rem;
			font-weight: bold;
			align-items: baseline;
			@include mp {
				font-size: 2rem;
			}
			.bill-info-amount-price {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				.bill-info-amount-price-r {
					font-size: 1.5rem;
					@include mp {
						font-size: 2rem;
					}
				}
				.bill-info-amount-price-k {
					font-size: 1rem;
					@include mp {
						font-size: 1.5rem;
					}
				}
			}
			.bill-info-amount-price-curr {
				font-size: 1rem;
				display: flex;
				margin-top: 0.75rem;
				font-weight: bold;
				@include mp {
					font-size: 1.5rem;
				}
			}
		}
		.bill-info-pick {
			margin-top: 0.75rem;
			font-size: 1rem;
			text-transform: uppercase;
			@include mp {
				font-size: 2rem;
			}
		}
		.bill-info-pick-date {
			margin-top: 0.5rem;
			font-size: 1.5rem;
			text-transform: uppercase;
			color: #7A7A7A;
			@include mp {
				font-size: 2.5rem;
			}
		}
		.bill-info-contact {
			margin-top: 1rem;
			font-size: 2rem;
			text-transform: uppercase;
			@include mp {
				font-size: 3rem;
			}
		}
		.bill-info-label {
			margin-top: 0.75rem;
			font-size: 0.8rem;
			text-transform: uppercase;
			@include mp {
				font-size: 1.8rem;
			}
		}
		.bill-info-value {
			margin-top: 0.75rem;
			font-size: 1.2rem;
			text-transform: uppercase;
			@include mp {
				font-size: 2.2rem;
			}
		}
	}
	.bill-items {
		display: flex;
		flex-direction: column;
		.bill-item {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 1rem;
			padding: 0rem 1rem 1rem 1rem;
			@include mp {
				font-size: 2.5rem;
			}
			.bill-item-left {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				text-align: left;
			}
			.bill-item-right {
				display: flex;
				flex-direction: row;
				margin-left: 2rem;
				.bill-item-right-r {
					font-size: 1.5rem;
					@include mp {
						font-size: 2.5rem;
					}
				}
				.bill-item-right-k {
					font-size: 0.7rem;
					@include mp {
						font-size: 1.7rem;
					}
				}
			}
		}
	}
	.bill-footer {
		background-color: var(--fgryeli);
		height: 2rem;
		line-height: 2rem;
		font-size: 1rem;
		border-bottom-left-radius: 1rem;
		border-bottom-right-radius: 1rem;
	}
}
.date-input {
	text-align: center;
	height: 42px;
	font-size: 16px;
}
.react-tel-input {
	.form-control {
		// border: 2px solid #000000 !important;
		height: 42px !important;
		font-size: 16px;
	}
	.input-wrong {
		// border: 2px solid red !important;
	}
	.flag-dropdown {
		height: 42px;
		font-size: 16px;
		// border: 2px solid #000000 !important;
		padding-left: 5px;
		border-radius: 2rem !important;
	}
	input {
		font-size: 16px !important;
		padding-left: 48px !important;
		width: 100% !important;
		height: 42px;
		max-height: 42px;
		font-size: 16px;
		&:focus {
			outline: none;
		}
	}
}
.input-container {
	width: 40%;
	// margin-left: 24px;
	input {
		width: 100%;
		height: 42px;
		font-size: 16px;
		&:focus {
			outline: none;
		}
	}
	&-first {
		margin-left: 0px;
	}
	&-m {
		margin-left: 1.5rem;
	}
}
.input-margin {
	margin-left: 2rem;
	@include mp {
		margin-left: 0rem;
	}
}
.order-success {
	background-color: var(--wtogrey);
	border-radius: 1rem;
	width: 100%;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 2rem;
	line-height: 2.25rem;
	text-align: center;

	color: #000000;
	padding: 2rem 0rem;
	.order-success-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		.order-success-row-btn {
			margin-top: 1.5rem;
			border-radius: 1rem;
			padding: 1.5625rem 4.0625rem;
			background-color: var(--fgryeli);
			width: fit-content;
			cursor: pointer;
		}
		.order-success-row-bill {
			margin-top: 1rem;
			cursor: pointer;
		}
	}

}
.pay {
	display: flex;
	flex-direction: column;
	background-color: #FFFFFF;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	.pay-data {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		.pay-data-col {
			display: flex;
			flex-direction: column;
			.pay-data-title {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 2rem;
				line-height: 2.5rem;
				color: #000000;
			}
			.pay-inp {
				margin-top: 1rem;
				&-short {
					width: 70%;
				}
			}
			.pay-input-title {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 1rem;
				line-height: 1.2rem;
				color: #000000;
				text-align: left;
			}
			.pay-input {

				background: #FFFFFF;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
				width: 100%;
				margin-left: 0px;
				border-radius: 2rem;
				border: none;
				outline: none;
				padding: 0.5rem;

			}
		}
	}
}

.modal {
	display: block; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 10; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
	border-radius: 1rem;
	background-color: #fefefe;
	margin: 15% auto; /* 15% from the top and centered */
	padding: 2rem 2rem 4rem 2rem;
	border: 1px solid #888;
	width: 50%; /* Could be more or less, depending on screen size */
	height: fit-content;
  }
  
  /* The Close Button */
  .close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
  }
  .modal-row {
	  display: flex;
	  flex-direction: row;
	  justify-content: center;
	  .modal-row-btn {
		  background-color: var(--fgryeli);
		  cursor: pointer;
		  padding: 1.5rem 4rem;
		  border-radius: 1rem;
		  font-size: 1.2rem;
		  white-space: nowrap;
		  &:nth-child(2) {
			  margin-left: 2rem;
			  @include mp {
				margin-left: 0rem;
			  }
		  }
		  @include mp {
			margin-top: 16px;
			font-size: 2rem;
		  }
	  }
  }
  .order-confirm-row {
	  margin-top: 4rem;
	  font-size: 1.4rem;
	  @include mp {
		  font-size: 2rem;
	  }
  }
  .order-confirm-row-buttons {
	  margin-top: 4rem;
	  @include mp {
		margin-top: 2rem;
		font-size: 2rem;
		flex-wrap: wrap;
	}
}
  .input-wrong {
	border-color: red !important;
	&>div {
		border-color: red !important;
	}
  }
  .dt-container {
	margin-left: 1.5rem;
	@include mp {
		margin-left: 0rem;
	}
	input:focus {
		outline: none;
	}
	width: 40%;
	.react-datepicker-wrapper {
		  width: 100% !important;
	}
  }
  .react-datepicker-wrapper {
	  input:focus {
		  outline: none;
	  }
  }

  .react-datepicker {
	font-size: 1em !important;
  }
  .react-datepicker__header {
	padding-top: 0.8em !important;
  }
  .react-datepicker__month {
	margin: 0.4em 1em  !important;
  }
  .react-datepicker__day-name, .react-datepicker__day {
	width: 1.7em  !important;
	line-height: 1.7em !important;
	margin: 0.166em !important;
  }
  .react-datepicker__current-month, .react-datepicker__time {
	font-size: 1em !important;
  }
  .react-datepicker__navigation {
	top: 1em !important;
	line-height: 1.7em !important;
	border: 0.45em solid transparent !important;
  }
  .react-datepicker__navigation--previous {
	border-right-color: #ccc !important;
	left: 1em !important;
  }
  .react-datepicker__navigation--next {
	border-left-color: #ccc !important;
	right: 1em !important;
  }
  .react-datepicker-time__header {
	@include mp {
		font-size: 2em !important;
	}
  }
  .react-datepicker__time-list-item {
	  @include mp {
		font-size: 4em !important;
	}
  }