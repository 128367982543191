.conditions {
	text-align: left;
	margin-top: 2rem;
	.header1 {
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 1.5rem;
		line-height: 1.7rem;
		margin-top: 1.5rem;

		color: var(--fgryeli);
	}
	.header2 {
		font-family: Roboto;
		font-style: normal;
		font-weight: 700;
		font-size: 1.5rem;
		line-height: 2.0rem;
		margin-top: 1.5rem;
		color: var(--grgr);

	}
	.text {
		color: var(--c);
	}
	.m1 {
		margin-left: 1rem;
	}
	.m2 {
		margin-left: 2rem;
	}

	ul {
		list-style: none;
	}
	  
	ul {
		padding-left: 0px;
		color: var(--c);
	}
	ul li::before {
		content: "\2022";
		color: var(--grgr);
		font-weight: bold;
		display: inline-block; 
		width: 1rem;
	}
	.lbox {
		margin-top: 2rem;
		.lbox-line {
			border-top: 2px solid #FF0000;
			width: 10vw;
		}
		.lbox-text {
			padding-top: 1rem;
			color: var(--c);

		}
		
	}
	a {
		color: var(--c);
	}
	.final {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 1.2rem;
		line-height: 1.7rem;
		text-align: center;
		margin-top: 1rem;
		margin-bottom: 30rem;

		color: var(--fgryeli);
	}
	.logos {
		margin-top: 3rem;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		.link {
			text-decoration: none;
		}
		.visa {
			background-image: url('../assets/images/visa.png');
			width: 10rem;
			height: 10rem;
			background-repeat: no-repeat;
			background-size: cover;
			margin-left: 1rem;
			margin-top: 0px;
		}
		.pumb-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			color: var(--c);
			font-size: 3rem;
			text-decoration: none;
			.pumb {
				background-image: url('../assets/images/pumb.png');
				width: 10rem;
				height: 10rem;
				background-repeat: no-repeat;
				background-size: cover;
				margin-left: 1rem;
				margin-top: 0px;
			}
		}
		.mastercard {
			background-image: url('../assets/images/mastercard.png');
			width: 25rem;
			height: 10rem;
			background-repeat: no-repeat;
			background-size: cover;
			margin-left: 1rem;
			margin-top: 0px;
		}
	}
}