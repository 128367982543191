@import '../../global.scss';
.promotions {
	margin-top: 1.8125rem;
	z-index: 2;
	.promotion {
		width: 100%;	
		max-width: 100%;
		height: 21.4375rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		margin-bottom: 4.1875rem;
		@include mp {
			margin-bottom: 0rem;
		}
	}
	.promotion-text {
		// border: 1px solid var(--yeltob);
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 2rem;
		line-height: 3.2rem;
		text-align: center;

		color: var(--yeltob);
		padding: 2rem;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		.promotion-text-content {
			height: 100%;
			overflow-y: scroll;
		}

	}
	.promotion1 {
		background-image: url('../assets/images/action1.png');
	}
	.promotion2 {
		background-image: url('../assets/images/action2.png');
	}
	.promotion3 {
		background-image: url('../assets/images/action3.png');
	}
	.promotion4 {
		background-image: url('../assets/images/action4.png');
	}
	.promotion5 {
		background-image: url('../assets/images/action5.png');
	}
	.promotion6 {
		background-image: url('../assets/images/action6.png');
	}
}
.promotions-type {
	display: flex;
	flex-direction: row;
	justify-content: center;
	@include mp {
		font-size: 2.6rem;
	}
	.promotion-type {
		padding: 0.375rem;
		width: 13.5rem;
		border: 2px solid var(--ygr);
		box-sizing: border-box;
		border-radius: 14px;
		color: var(--yeltob);
		cursor: pointer;
		margin-left: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		@include mp {
			width: 22.2rem;
			border-radius: 8px;
		}
	}
	.promotion-type.chosen {
		background-color: var(--ygr);
		color: #000000;
	}
	.promotion-type:first-of-type {
		margin-left: 0rem;
	}
}

