$phonem: 640px;
$phoneme: 767px;
$tabletm: 768px;
$tabletme: 1023px;
$desktopm: 1024px;

@mixin mp {
	@media (max-width: #{$phoneme}) {
		@content;
	}
}
@mixin mt {
	@media only screen and (min-width: #{$tabletm}) and (max-width: #{$tabletme}) {
		@content;
	}
}
@mixin md {
	@media only screen and (min-width: #{$desktopm}) {
		@content;
	}
}

:root {
    --bg: #000000;
	--c: #FFFFFF;
	--inv: invert(0);
	--inv1: invert(1);
	--bggryel: linear-gradient(0deg, #AFC04E 14.62%, #D5E670 45.92%, #DDEA73 87.42%);
	--fgryel: #9EDA93;
	--fgryeli: #DDEA73;
	--yeltob: #F1F087;
	--yel1tob: #DDEA73;
	--wtog: #FFFFFF;
	--wtob: #FFFFFF;
	--wtogrey: #FFFFFF;
	--gtor: #9EDA93;
	--yeldtol: #D8E872;
	--shadow: none;
	--ygr: #F1F087;
	--grgr: #9EDA93;

	--bgr: #359d78;
	--bgr2: #359d78;
	--bgr3: rgb(109, 183, 141);
	--by: #d8e872;
    --wtog: #FFFFFF;
    --wtolg: #FFFFFF;
	// --logo: url('client/assets/images/logo_black.svg');
}

a {
	z-index: 2;
}
