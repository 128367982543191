@import '../../global.scss';

.store-container {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 60%;
	font-size: 1rem;
	@include mp {
		font-size: 3rem;
		width: 60%;
	}
	.store-container-wrapper {
		display: flex;
		flex-direction: column;
		width: 60%;
		align-items: center;
		&>div {
			width: 100%;
		}
		.store-select.store-select-shadow {
			&>div {
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			}
		}
	}
	.store-title {
		margin-top: 5.81rem;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 2.5rem;
		line-height: 3.5rem;
		/* identical to box height */
		text-align: center;
        text-transform: uppercase;
	
		color: var(--yel1tob);
	
	
	}
	.store-select-label {
		margin-top: 2.5rem;
		margin-bottom: 0.3rem;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 1.5rem;
		line-height: 1.75rem;
		text-align: left;
		text-transform: uppercase;
		@include mp {
			font-size: 2rem;
			line-height: 3rem;
		}
	
		color: var(--yel1tob);
	}
	.store-desc-label {
		margin-top: 2.5rem;
		margin-bottom: 0.3rem;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 1.5rem;
		line-height: 1.75rem;
		text-align: left;
		@include mp {
			font-size: 2rem;
			line-height: 3rem;
		}
	
		color: var(--yel1tob);
	}
	.store-desc-label-link {
		text-decoration: underline;
		color: var(--yel1tob);
	}
	.store-button {
		margin-top: 2.18rem;

		background: #9EDA93;
		border-radius: 1.34rem;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 1.5rem;
		line-height: 1.75rem;
		text-align: center;

		color: #FAFAFA;
		width: min-content;
		padding: 0.5rem 4.18rem;
		justify-self: center;
		align-self: center;
		cursor: pointer;
		&.disabled {
			background: #9EDA93;
			cursor: not-allowed;
		}
		@include mp {
			padding: 2rem 6rem;
			font-size: 3rem;
		}
	}
}
.store-categories {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 2rem;
	justify-content: flex-start;
}
.search-container {
	position: relative;
	display: flex;
	margin-top: 2rem;
	.search-icon {
		position: absolute;
		left: 0.5rem;
		top: 0.4rem;
		width: 0.8rem;
		@include mp {
			font-size: 2.5rem;
			top: 0.2rem;
			width: 1.5rem;
		}
	}
	.search {
		border: none;
		box-shadow: var(--shadow);
		border-radius: 1rem;
		width: 100%;
		padding-left: 1.7rem;
		outline: none;
		background: var(--wtogrey);
		@include mp {
			padding-left: 3rem;
		}
	}

}
.articles {
	margin-top: 2rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	.article-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: calc(min(24vw, 300px));
		height: calc(min(24vw, 300px) + 10rem);
		@include mp {
			width: calc(min(45vw, 250px));
			height: calc(min(45vw, 250px) + 10rem);
		}
		.article {
			width: 95%;
			height: 95%;
			text-transform: none;
			text-decoration: none;
			color: var(--bg);
			cursor: pointer;
			border-radius: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			position: relative;
			background-color: #FFFFFF;

			box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.25);

			&:hover {
				width: 100%;
				height: 100%;
			}
			.article-title {
				width: 100%;
				height: 5rem;
				border-top-left-radius: 1rem;
				border-top-right-radius: 1rem;
				background-color: var(--fgryeli);
				color: #000000;
				position: absolute;
				top: 0px;
				left: 0px;
				display: none;
				@include mp {
					font-size: 2rem;
					display: none;
				}
			}
			.article-promo {
				background-color: #FF0000;
				color: #FFFFFF;
				display: block;
				min-width: 40%;
				width: fit-content;
				border-top-left-radius: 1rem;
				border-bottom-right-radius: 1rem;
				position: absolute;
				font-size: 2rem;
				@include mp {
				}
			}
			.article-promo-period {
				font-size: 1.3rem;
				color: red;
				width: fit-content;
				position: absolute;
				right: 1.5rem;
				top: 0.5rem;
				@include mp {
					font-size: 1.6rem;
				}
			}
			.article-title-price {
				background-color: #B1E7A7;
				color: #000000;
				min-width: 40%;
				width: fit-content;
				border-top-left-radius: 1rem;
				border-bottom-right-radius: 1rem;
				padding-left: 1rem;
				display: flex;
				align-items: flex-start;
				position: absolute;
				.article-price-1 {
					font-size: 3rem !important;
					@include mp {
						font-size: 4rem !important;
					}
				}
				.article-price-2 {
					font-size: 2rem !important;
					@include mp {
						font-size: 2.5rem !important;
					}
				}
			}
			.article-image {
				width: calc(min(24vw, 300px) * 0.95);
				height: calc(min(24vw, 300px) * 0.95);
				@include mp {
					width: calc(min(45vw, 250px) * 0.95);
					height: calc((min(45vw, 250px) + 5rem) * 0.95);
				}
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				border-radius: 1rem;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				background-color: #FFFFFF;
				@include mp {
					// margin-top: 5rem;
					border-top-left-radius: 0rem;
					border-top-right-radius: 0rem;
				}
			}
			.article-desc {
				font-size: 2.5rem;
				line-height: 2.5rem;
				color: #000000;
				text-align: left;
				padding-left: 1rem;
				padding-right: 1rem;
				height: 7.5rem;
				max-height: 7.5rem;
				display: none; 
				@include mp {
					display: flex;
					overflow: hidden;
				}
			}
			.article-details {
				color: var(--bg);;
				height: 25%;
				border-radius: 1rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				// padding-right: 0.5rem;
				.article-text {
					padding: 0.84275rem 0.6296rem;
					box-sizing: border-box;
					// background-color: #DAE972;
					background-color: #FFFFFF;
					border-radius: 1rem;
					width: 100%;
					// height: 100%;
					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 1rem;
					line-height: 1.1rem;
	
					text-transform: none;
					text-decoration: none;
					color: #000000;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					@include mp {
						display: none;
					}
					.article-name {
						height: 2.2rem;
					}
					.article-name-unit {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						text-align: left;
						width: 100%;
						@include mp {
							visibility: collapse;
						}
						.article-unit {
							align-self: center;
						}
					}
				}
				.article-price-btns {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					width: 100%;
					padding: 0.6296rem 0.6296rem;
					box-sizing: border-box;
					.article-price-btns-btns {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;
						.article-price-btns-btns-fav, .article-price-btns-btns-basket {
							width: 3rem;
							height: 3rem;
							@include mp {
								width: 4.5rem;
								height: 4.5rem;
							}
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							.favourites {
								width: 2.5rem;
								height: 2.5rem;
								@include mp {
									width: 3.5rem;
									height: 3.5rem;
								}
								cursor: pointer;
								path {
									stroke: #9eda93;
								}
								&-chosen {
									path {
										stroke: #FF154D;
									}
								}
							}
							.basket {
								width: 2.5rem;
								height: 2.5rem;
								@include mp {
									width: 3.5rem;
									height: 3.5rem;
								}
								cursor: pointer;
								box-sizing: border-box;
								padding: 0.2rem;
								path {
									// fill: var(--c);
									fill: #FFFFFF;
								}
								&-chosen {
									path {
										fill: #FF154D;
									}
								}
							}
						}
						.article-price-btns-btns-basket  {
							background-color: #9eda93;
							border-radius: 1rem;
							@include mp {
								margin-left: 1rem;
							}
						}
					}
					.article-price-old {
						@extend .article-price;
						color: #808080 !important;
						position: absolute;
						svg {
							position: absolute;
						}
					}
				}
			}
		}
	}
}
.articles-more {
	cursor: pointer;
	background: #B1E7A7;
	border-radius: 2rem;
	width: fit-content;
	color: var(--c);
	font-size: 1rem;
	margin-left: auto;
	margin-right: auto;
	padding: 0.5rem 1rem;
	margin-top: 1rem;
}
.article-price {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	color: #000000;
	.article-price-1 {
		font-size: 3rem;
		line-height: 3rem;
		@include mp {
			font-size: 5rem;
			line-height: 5rem;
		}
	}
	.article-price-2 {
		font-size: 1.5rem;
		line-height: 1.5rem;
		@include mp {
			font-size: 3rem;
			line-height: 3rem;
		}
	}
}
.art-btn {
	cursor: pointer;
	margin-top: 2.875rem;
	background: var(--fgryeli);
	border-radius: 1rem;
	text-align: center;
	// padding: 1.4375rem 3.3125rem;
	padding: 1.0rem 2.0rem;
	width: 70%;

	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 2.25rem;
	line-height: 2.625rem;

	color: #000000;
}
.artc-qty {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 2.875rem;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 4rem;
	line-height: 4rem;
	box-sizing: border-box;
	height: 4.3rem;
	// width: 83%;
	.artc-qty-left {
		// padding: 0.25rem 3.3125rem;
		background-color: var(--fgryeli);
		width: 30%;
		color: black;
		border-top-left-radius: 1rem;
		border-bottom-left-radius: 1rem;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid var(--fgryeli);
		height: 100%;
		box-sizing: border-box;
		@include mp {
			// padding: 0.1rem 3.3125rem;
		}
	}
	.artc-qty-qty {
		background-color: #FFFFFF;
		color: #000000;
		height: 100%;
		width: 40%;
		display: flex;
		justify-content: center;
		align-items: center;
		// border: 1px solid var(--fgryeli);
		box-sizing: border-box;
		max-height: 100%;
		// padding: 3px 0px 2px;
		@include mp {
			border: none;
			// padding: 1px 0 0px;
		}
		&-v {
			text-align: center;
			border: none;
			font-size: 3.5rem;
			width: 100%;
			box-sizing: border-box;
		}
	}
	.artc-qty-right {
		// padding: 0.25rem 3.3125rem;
		background-color: var(--fgryeli);
		color: black;
		width: 30%;
		border-top-right-radius: 1rem;
		border-bottom-right-radius: 1rem;
		border: 1px solid var(--fgryeli);
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		box-sizing: border-box;
		@include mp {
			// padding: 0.1rem 3.3125rem;
		}
	}
}
.art-page-container {
	position: relative;

	.art-page-carousel {
		margin-top: 10rem;
	}
	.art-page {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 1rem;
		&-faded {
			opacity: 0.2;
		}
		.art-page-desc {
			display: flex;
			flex-direction: column;
			width: 40%;
			@include mp {
				width: 50%;
			}
			.art-page-btnbasket {
				width: 85%;
				box-sizing: border-box;
				height: 4.3rem;
				@include mp {
					width: 90%;
				}
				@include mt {
					width: 95%;
				}
				.art-btn {
					white-space: nowrap;
					width: 100%;
					box-sizing: border-box;
				}
				.art-page-basket {
					cursor: pointer;
					margin-top: 1.5rem;
					font-size: 1.2rem;
					a {
						color: var(--yeltob);
						text-decoration: none;
					}
				}
			}
			.article-price-1 {
				font-size: 5rem;
				line-height: 5rem;
			}
			.article-price-2 {
				font-size: 3rem;
				line-height: 3rem;
			}
			.art-name {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 3rem;
				line-height: 3.6rem;
				border-bottom: var(--yeltob) solid 1px;
	
				color: var(--yeltob);
				text-align: left;
    			padding-bottom: 1rem;
			}
			.art-qty {
				margin-top: 3rem;
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 1.5rem;
				line-height: 1.75rem;
				text-align: left;
	
				color: var(--yeltob);
	
				display: flex;
				align-items: center;
				@include mp {
					font-size: 3rem;
					flex-wrap: wrap;
					margin-bottom: 2rem;
					margin-top: 1rem;
				}
				svg {
					color: var(--fgryeli);
					margin-left: 0.5rem;
					cursor: pointer;
					@include mp {
						margin-left: 1rem;
					}
				}
				div {
					display: flex;
					flex-direction: row;
					align-items: center;
					@include mp {
						margin-top: 2rem;
						width: 100%;
						justify-content: center;
						font-size: 3rem;
					}
				}
			}
			.article-price {
				margin-top: 1rem;
				justify-content: flex-start;
				color: var(--yeltob);
			}
		}
		.art-page-img {
			// width: 30vw;
			// height: 30vw;
			width: 24rem;
			height: 24rem;
			background-color: #C4C4C4;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-color: #FFFFFF;
			border-radius: 1rem;
		}
	}
	.basket-continue {
		position: absolute;
		top: 10%;
		left: 30%;
		white-space: nowrap;
		width: min-content;
		@include mp {
			top: 40%;
			left: 10%;
			width: 80%;
		}
	}
}

.promoart {
	.promoart-banner {
		background: url('../assets/images/discounts.png') no-repeat;
		background-size: contain;
		background-position: center;
		width: 100%;
		height: 13.5rem;
		display: flex;
		justify-content: center;
		position: relative;
		.promoart-banner-category {
			position: absolute;
			bottom: 20%;
			width: 25%;;
			height: fit-content;
			@include mp {
				width: 40%;
			}
			.promoart-banner-category-label {
				margin-bottom: 0.3rem;
			}
			.promoart-category {
				z-index: 100;
				@include mp {
					font-size: 2rem;
				}
				div {
					background-color: #FAE150;
					border-color: black;
					svg {
						fill: black;
						@include mp {
							width: 8px;
							height: 8px;
						}
					}
				}
				.react-select {
					&__control { border-radius: 1rem; }
					&__value-container { border-radius: 1rem; }
					&__single-value { text-transform: capitalize; }
					&__indicators { border-radius: 1rem; }
					&__indicator { border-radius: 1rem; }
					&__option { background-color: #FFFFFF; color: #000000; text-transform: capitalize; }
				}
			}
		}
	}
}
.more18 {
	position: fixed;
    z-index: 10;
    top: 30vh;
	width: 100vw;
	.more18-btns {
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
		.art-btn {
			background-color: var(--fgryel);
			width: 20vw;
			font-size: 1.5rem;
			@include mp {
				width: 50vw;
				font-size: 3rem;
			}
			@include mt {
				width: 40vw;
				font-size: 2rem;
			}
			margin-top: 2rem;
		}
	}
	.more18-text {
		margin-top: 2rem;
		color: #FF0000;
		white-space: normal;
		width: 100%;
		width: 30vw;
		margin-left: auto;
		margin-right: auto;
		@include mp {
			width: 70vw;
			font-size: 2.5rem;
		}
		@include mt {
			width: 60vw;
			font-size: 2rem;
		}
	}
}