@import '../../global.scss';
.logo-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	.logo {
		background-image: var(--logo);
		width: 25.9375rem;
		height: 9.375rem;
		background-repeat: no-repeat;
		background-size: contain;
		margin-top: 3.75rem;
		@include mp {
			margin-top: 0rem;
			width: 18rem;
			height: 6.3rem;
		}
	}
}
.we-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 6rem;
	@include mp {
		margin-top: 3rem;
	}
	.we-text {
		width: 60%;
		text-align: left;
		margin-top: 0px;
		font-size: 1.5rem;
		line-height: 1.75rem;
		@include mp {
			width: 100%;
			font-size: 2.2rem;
			line-height: 2.5rem;
		}
	}
}
.we-mob-icon {
	height: 40rem;
	width: 100vw;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: -2.1rem;
	@include mt {
		display: none;
	}
	@include md {
		display: none;
	}
}
.we-mob1 {
	@extend .we-mob-icon;
	background-image: url('../assets/images/horse2.png');
}
.we-mob2 {
	@extend .we-mob-icon;
	background-image: url('../assets/images/we-mob2.png');
}
.we-image {
	background-image: url('../assets/images/we.jpg');
	background-position: center;
	width: 40%;
	height: 30.875rem;
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: 1.5rem;
	border-radius: 10px;
	@include mp {
		display: none;
	}
}
.we-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 2rem;
	line-height: 2.375rem;
	text-align: center;
	color: #ddea73;
	margin-top: 1.375rem;
	@include mp {
		font-size: 2.2rem;
		line-height: 2.5rem;
	}
}
.we-text-subtitle {
	@extend .we-text;
	color: #9EDA93;
	@include mp {
		font-size: 2.2rem;
		line-height: 2.5rem;
	}
}

.we-value {
    box-sizing: border-box;
    width: calc(100% + 4.3125rem);
    margin-left: -2.15rem;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	// background-color: #1B1919;
	padding: 1rem 2.25rem 3.5625rem 4.375rem;
	// margin-top: 2.65625rem;
	.we-value-left {
		display: flex;
		flex-direction: row;
		max-width: 60%;
		@include mp {
			display: none;
			max-width: 100%;
		}
	}
	.we-value-text {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 1.5rem;
		line-height: 1.75rem;
		color: #ddea73;
		// max-width: 60%;
		text-align: right;
		margin-right: 6.8125rem;
	}
	.we-value-image {
		background-image: url('../assets/images/horse2.png');
		width: 40%;
		height: 19.0625rem;
		background-size: contain;
		background-repeat: no-repeat;
		margin-left: 1.5rem;
		@include mp {
			display: none;
			width: 100%;
		}
	}
}
.we-value-bottom {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 1.5rem;
	line-height: 1.75rem;
	text-align: center;

	color: #DDEA73;
	@include mp {
		font-size: 2.3rem;
		line-height: 2.5rem;
	}
}
.we-hide-big {
	@include mt {
		display: none;
	}
	@include md {
		display: none;
	}
}
.we-content-desktop {
	display: block;
	@include mp {
		display: none;
	}
}
.we-content-mobile {
	display: none;
	@include mp {
		display: block;
	}
}
