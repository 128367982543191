@import '../../global.scss';
.cafes {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	// margin-top: 3rem;
	// display: grid;
	// grid-template-columns: 32% 32% 32%;
	// grid-column-gap: 2%;
	// grid-row-gap: 3rem;
	// @include mp {
	// 	grid-template-columns: 48% 48%;
	// 	grid-column-gap: 4%;
	// }
	.cafe {
		width: 100%;
		height: 22.5rem;
		border-radius: 10px;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		@include mp {
			border-radius: 6px;
		}
		.cafe-label {
			min-height: 30%;
		    background: #9EDA93;
    		border-radius: 10px;

			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 2rem;
			line-height: 2.34375rem;

			color: var(--c);
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

		}
	}
	.cafe1 { background-image: url('../assets/images/cafe1.png'); }
	.cafe2 { background-image: url('../assets/images/cafe2.png'); }
	.cafe3 { background-image: url('../assets/images/cafe3.png'); }
	.cafe4 { background-image: url('../assets/images/cafe4.png'); }
	.cafe5 { background-image: url('../assets/images/cafe5.png'); }
	.cafe6 { background-image: url('../assets/images/cafe6.png'); }
}
.cafe-det {
	display: flex;
	flex-direction: row;
	justify-content: center;
	img {
		width: 100%;
	}
}

