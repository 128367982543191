.construction {
	
	height: 30vh;
	@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
	@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
	@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

	.construction-label {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 2.25rem;
		line-height: 2.625rem;
		text-align: center;

		color: var(--c);

		margin-top: 4%;
    	margin-left: -1rem;
	}
	.container {
		display: flex;
		margin-top: 10%;
	}
	.gears {
		width: 30%;
		position: relative;

	}
	.gear {
		background-image: url('../assets/images/gear.svg');
		background-repeat: no-repeat;
		background-size: contain;
		-webkit-animation:spin 4s linear infinite;
    	-moz-animation:spin 4s linear infinite;
		animation:spin 4s linear infinite;
		position: absolute;
	}
	.gear-big {
		@extend .gear;
		width: 8.71875rem;
		height: 8.71875rem;
		bottom: 0px;
		top: 4.1875rem;
		right: 3.5625rem;
	}
	.gear-middle {
		@extend .gear;
		width: 6.75rem;
		height: 6.75rem;
		top: 0px;
		right: 1.3125rem;
	}
	.gear-small {
		@extend .gear;
		width: 5rem;
		height: 5rem;
		right: 0px;
		top: 5rem;
	}
}