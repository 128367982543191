@import '../../global.scss';

@font-face {
	font-family: "Proxima Nova Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Condensed\ Regular\ TheFontsMaster.com.otf');
}
@font-face {
	font-family: "Proxima Nova Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Condensed\ Bold\ TheFontsMaster.com.otf');
	font-weight: 700;
}
@font-face {
	font-family: "Proxima Nova Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Condensed\ Extrabold\ TheFontsMaster.com.otf');
	font-weight: 900;
}
@font-face {
	font-family: "Proxima Nova Extra Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Extra\ Condensed\ Regular\ TheFontsMaster.com.otf');
}
@font-face {
	font-family: "Proxima Nova Extra Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Extra\ Condensed\ Bold\ TheFontsMaster.com.otf');
	font-weight: 700;
}
@font-face {
	font-family: "Proxima Nova Extra Condensed";
	src: url('../assets/fonts/Mark\ Simonson\ \ Proxima\ Nova\ Extra\ Condensed\ Extrabold\ TheFontsMaster.com.otf');
	font-weight: 900;
}

.bonus-start-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 1.21875rem;
	.bonus-start {
		border-radius: 5rem;
		background-color: var(--wtog);
		width: 43.58rem;
		height: 12.33rem;
		z-index: 116;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 1.75rem;
		display: flex;
		flex-direction: row;
		// justify-content: space-between;;
		justify-content: center;
		.bonus-gear {
			background-image: url('../assets/images/bonuses/bonus-gear.png');
			background-repeat: no-repeat;
			width: 9.83rem;
			height: 9.83rem;
			background-size: contain;
			display: flex;
			flex-direction: row;
			align-items: center;
			.bonus-gear-text {
				font-size: 3rem;
				font-family: "Proxima Nova Extra Condensed";
				font-weight: bold;
				color: rgb(255, 255, 255);
				text-transform: uppercase;
				line-height: 1;
				text-align: center;
				-moz-transform: matrix( 0.81130762516365,0,0,0.81130762516365,0,0);
				-webkit-transform: matrix( 0.81130762516365,0,0,0.81130762516365,0,0);
				-ms-transform: matrix( 0.81130762516365,0,0,0.81130762516365,0,0);
				z-index: 120;
			}
		}
		.bonus-start-text {
			font-size: 2.4166rem;
			font-family: "Proxima Nova Condensed";
			font-weight: 900;
			// color: rgb(1, 1, 1);
            color: var(--gtow);
			text-transform: uppercase;
			line-height: 1.2;
			text-align: left;
			z-index: 121;
			width: 31rem;
			.bonus-start-text-zebra {
				color: var(--bgr2);
			}
		}
	}
}
.bonus-adv {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.bonus-adv-title {
		justify-self: center;
		margin-top: 4.75rem;
		font-size: 2.166rem;
		font-family: "Proxima Nova Condensed";
		font-weight: 900;
		color: #359d78;
		text-transform: uppercase;
		line-height: 1.2;
		text-align: center;
		z-index: 5;
		.bonus-adv-zebra {
			color: var(--wtolg);
		}
	}
	.bonus-adv-zebra-list {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		width: 100%;
		.bonus-adv-list {
			height: fit-content;
			margin-top: 2rem;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: fit-content;
			border-left: 1px var(--c) dashed;
			.bonus-adv-text {
				display: flex;
				font-size: 1.2916rem;
				font-family: "Proxima Nova Condensed";
				font-weight: 900;
				color: var(--wtob);
				line-height: 1;
				z-index: 6;
				text-transform: uppercase;
				.bonus-adv-text-c {
					background-color: var(--yg);
					border-radius: 1rem;
					margin-left: -0.5rem;
					margin-right: 1rem;
					font-size: 0.7rem;
					height: 0.9rem;
				}
				&:nth-child(1) {
					margin-bottom: 1.75rem;
					.bonus-adv-text-c {
						align-self: flex-start;
					}
				}
				&:nth-child(2) {
					margin-bottom: 1.75rem;
					.bonus-adv-text-c {
						align-self: center;
					}
				}
				&:nth-child(3) {
					.bonus-adv-text-c {
						align-self: flex-end;
					}
				}
			}
		}
		.bonus-zebra {
			background-image: url('../assets/images/bonuses/bonus-zebra.png');
			background-repeat: no-repeat;
			width: 35%;
			height: 24rem;
			background-size: contain;
		}
	}
}
.bonus-how {
	.bonus-wave {
		background-size: cover;
		display: flex;
    	flex-direction: column;
		justify-content: flex-start;
		align-items: center;
        position: relative;
        z-index: 10;
		&-b {
			// background-image: url('../assets/images/bonuses/bonus-wave.png');
			background-image: url('../assets/images/bonuses/bonus_wave_2.png');
			margin-top: -37rem;
			width: 100%;
			// height: 115rem;
			height: 70rem;

            /* background-size: contain; */
            // background-size: 94vw 78vh;
            /* margin-left: -290px; */
            @include mp {
                background-size: 73rem 55rem;
                background-position-x: -6rem;
                background-position-y: 9rem;
            }
            @include mt {
                background-size: 88rem 55rem;
                background-position-x: -157px;
                background-position-y: 173px;
            }
            @include md {
                background-size: 88rem 55rem;
                background-position-x: -157px;
                background-position-y: 173px;
            }
            /* background: no-repeat; */
            background-repeat: no-repeat;

			.bonus-wave-margin {
				height: 41%;
			}
		}
		&-w {
			background-image: url('../assets/images/bonuses/bonus_wave_2w.png');
			margin-top: -37rem;
			width: 100%;
			// height: 115rem;
			height: 70rem;
			// margin-top: -31rem;
			// width: 100%;
			// height: 107rem;
			// margin-left: 1rem;
            @include mp {
                background-size: 73rem 55rem;
                background-position-x: -6rem;
                background-position-y: 9rem;
            }
            @include mt {
                background-size: 88rem 55rem;
                background-position-x: -157px;
                background-position-y: 173px;
            }
            @include md {
                background-size: 88rem 55rem;
                background-position-x: -157px;
                background-position-y: 173px;
            }
            /* background: no-repeat; */
            background-repeat: no-repeat;

			.bonus-wave-margin {
				height: 41%;
			}
		}
		.bonus-how-title {
			font-size: 2.1666rem;
			font-family: "Proxima Nova Condensed";
			// color: rgb(32, 22, 0);
            color: var(--bg);
			text-transform: uppercase;
			line-height: 0.538;
			text-align: center;
			margin-top: 2rem;
			margin-left: 9rem;
			font-weight: 900;
		}
		.bonus-how-desc {
			font-size: 1.333rem;
			font-family: "Proxima Nova Condensed";
			font-weight: 900;
			color: var(--bg);
			line-height: 1.2;
			text-align: left;
			width: 50%;
			margin-left: 14rem;
			margin-top: 2rem;
		}
		.bonus-how-box {
			background-color: var(--gtolg);
			padding: 1.5rem;
			border-radius: 2rem;
			font-size: 1.375rem;
			font-weight: 900;
			font-family: "Proxima Nova Condensed";
			color: var(--wtog);
			line-height: 1.455;
			text-align: center;
			margin-top: 2rem;
		}
		.bonus-how-txt1 {
			font-size: 1.25rem;
			font-family: "Proxima Nova Condensed";
            font-weight: 700;
			color: var(--bg);
			line-height: 1.2;
			text-align: center;
			margin-top: 2rem;
			text-align: left;
			width: 85%;
		}
		.bonus-how-txt2 {
			font-size: 1.25rem;
			font-family: "Proxima Nova Condensed";
			color: var(--gtow);
			line-height: 0.7;
			font-weight: 900;
			margin-top: 1rem;
			text-align: left;
			width: 85%;
		}
		.bonus-bday {
			margin-top: 1.75rem;
			background-repeat: no-repeat;
			width: 33rem;
			height: 33rem;
			background-size: contain;
			&-b {
				background-image: url('../assets/images/bonuses/bouns-bday.png');
			}
			&-w {
				background-image: url('../assets/images/bonuses/bouns-bday-w.png');
			}
		}
	}
    .bonus-wave-aug {
        background-color: white;
        width: 80%;
        height: 100px;
    }
    .bonus-bwave {
		width: 100%;
		height: 88rem;
		background-size: contain;
		margin-top: -66rem;
		&-b {
			background-image: url('../assets/images/bonuses/bonus-bwave.png');
		}
		&-w {
			background-image: url('../assets/images/bonuses/bonus-bwave-w.png');
		}
	}
}
.bonus-mob {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -44rem;
	.bonus-mob-1 {
		font-size: 2.16667rem;
		font-family: "Proxima Nova Condensed";
		font-weight: 900;
		color: var(--c);
		line-height: 1.2;
		text-align: center;
	}
	.bonus-mob-2 {
		font-size: 1.5rem;
		font-family: "Proxima Nova Condensed";
		font-weight: 900;
		color: rgb(255, 255, 255);
		text-transform: uppercase;
		line-height: 0.778;
		text-align: center;
		background-color: #c7323a;
		width: fit-content;
		padding: 1.375rem;
		border-radius: 1rem;
		margin-top: 1rem;
	}
	.bonus-mob-3 {
		font-size: 1.666rem;
		font-family: "Proxima Nova Condensed";
		font-weight: 900;
		color: var(--bgr3);
		text-transform: uppercase;
		line-height: 1.2;
		text-align: center;
		margin-top: 1rem;
	}
	.bonus-mob-img {
		margin-top: 1.5rem;
		// background-image: url('../assets/images/bonuses/bonus-mob.png');
		width: 60%;
		height: 36rem;
		background-size: contain;
		background-repeat: no-repeat;
        &-b {
            background-image: url('../assets/images/bonuses/bonus-mob.png');
        }
        &-w {
            background-image: url('../assets/images/bonuses/bonus-mob-w.png');
        }
    }
}
.bonus-card {
	position: relative;
    margin-top: -290px;
	.bonus-header {
		display: flex;
		z-index: 2;
		.bonus-card-img {
			background-repeat: no-repeat;
			width: 100%;
			height: 22rem;
			background-size: contain;
			margin-top: 3rem;
	
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
	
			font-size: 1.541rem;
			font-family: "Proxima Nova Condensed";
			color: rgb(255, 255, 255);
			font-weight: 500;
			line-height: 1.2;
			text-align: center;
			&-b {
				background-image: url('../assets/images/bonuses/bonus-card.png');
			}
			&-w {
				background-image: url('../assets/images/bonuses/bonus-card-w.png');
			}
			strong {
				font-weight: 900;
			}
		}
	}
	.bonus-bg {
		background-color: white;
		width: 80%;
        @include mp {
            margin-top: 18rem;
        }
        @include mt {
            margin-top: -2rem;
        }
        @include md {
            margin-top: -2rem;
        }
		margin-left: auto;
		margin-right: auto;
		padding: 4.166rem 3.75rem 2.875rem 3.75rem;
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
        .bonus-bg-how-get {
            @include mp {
                margin-top: 25rem;
            }
            @include mt {
                margin-top: 240px;
            }
            @include md {
                margin-top: 240px;
            }
            font-size: 2.7rem;
            width: 27rem;
            text-transform: uppercase;
            font-weight: 900;
			font-family: "Proxima Nova Condensed";
			text-transform: uppercase;
			line-height: 1;
			text-align: center;
			color: #359d78;

        }
		.bonus-txt {
			font-size: 2.7rem;
			font-weight: 700;
			font-family: "Proxima Nova Condensed";
			color: rgb(0, 0, 0);
			text-transform: uppercase;
			line-height: 1;
			text-align: center;
			&-green {
				color: var(--bgr2);
			}
		}
		.bonus-bg-bar-cont {
			background-color: var(--by);
			width: fit-content;
			margin-left: auto;
			margin-right: auto;
			border-radius: 0.7rem;
			margin-top: 1rem;
			margin-bottom: 3.41rem;
			.bonus-bg-bar {
				background-image: url('../assets/images/bonuses/bonus-bar.png');
				background-repeat: no-repeat;
				width: 12.875rem;
				height: 9.25rem;
				background-size: contain;
				background-position: center;
			}
		}
		.bonus-phone{
			margin-top: 2.16rem;
			margin-left: auto;
			margin-right: auto;
			background-repeat: no-repeat;
			width: 24.41rem;
			height: 46rem;
			background-size: contain;
			background-position: center;
			&-b { 
				background-image: url('../assets/images/bonuses/bonus-phone.png');
			}
			&-w { 
				background-image: url('../assets/images/bonuses/bonus-phone-w.png');
			}
		}
		.bonus-card-zebra {
			margin-top: 2.7916rem;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 2rem;
			background-image: url('../assets/images/bonuses/bonus-card-zebra.png');
			background-repeat: no-repeat;
			width: 14.041rem;
			height: 8.7rem;
			background-size: contain;
		}
		.bonus-desc {
			font-size: 1.25rem;
			font-weight: 700;
			font-family: "Proxima Nova Condensed";
			color: rgb(17, 21, 24);
			line-height: 1.2;
			text-align: left;
			strong {
				color: #359d78;
                font-weight: 900;
                letter-spacing: 0.6px;
			}
			&-width {
				width: 64%;
			}
		}
		.bonus-part {
			font-size: 2.7rem;
			font-weight: 900;
			font-family: "Proxima Nova Condensed";
			text-transform: uppercase;
			line-height: 1;
			text-align: center;
			color: #359d78;
			margin-top: 7.625rem;
			margin-bottom: 1rem;
		}
		.bonus-square {
			border-radius: 3rem;
			background-color: rgb(255, 255, 255);
			box-shadow: 0px 0px 60px 30px rgba(10, 11, 12, 0.22);
			width: 60%;
			margin: 2rem auto 3rem auto;
			padding: 1rem;
			ul {
				list-style: none;
				li {
					font-size: 1.25rem;
					font-family: "Proxima Nova Condensed";
					color: rgb(17, 21, 24);
					line-height: 1.2;
					text-align: left;
                    font-weight: 700;
					&:before {
						content: "\2022";
						color: var(--bgr2);
						font-weight: bold;
						display: inline-block; 
						width: 1em;
						margin-left: -1em;
					}
				}
			}
		}
	}
	.bonus-ellipse {
		background-repeat: no-repeat;
		width: 100%;
		height: 15rem;
		background-size: contain;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		bottom: 70rem;
		&-b {
			background-image: url('../assets/images/bonuses/bonus-ellipse.png');
		}
		&-w {
			background-image: url('../assets/images/bonuses/bonus-ellipse-w.png');
		}
		@include mp {
			bottom: 78rem;
		}
		
		.bonus-ellipse-text {
			margin-top: -2rem;
			font-size: 2.166rem;
			font-family: "Proxima Nova Condensed";
			color: rgb(255, 255, 255);
			font-weight: 900;
			line-height: 1.2;
			text-align: center;
			@include mp {
				margin-top: -4rem;
			}
		}
	}
	.bonus-rules {
		margin-top: 3.541rem;
		.bonus-rules-title {
			font-size: 1.8rem;
			font-family: "Proxima Nova Condensed";
			color: var(--c);
			text-transform: uppercase;
			line-height: 1.2;
			text-align: center;
			font-weight: 900;
		}
		.bonus-btn {
			&-b {
				background-image: url('../assets/images/bonuses/bonus-btn.png');
			}
			&-w {
                background-image: url('../assets/images/bonuses/bonus-btn.png');
				// background-image: url('../assets/images/bonuses/bonus-btn2.png');
			}
			width: 12rem;
			height: 3rem;
			background-size: contain;
			background-repeat: no-repeat;
			margin: 1rem auto 0 auto;
			cursor: pointer;
		}
		a {
			text-decoration: none;
			.bonus-reg {
				width: 12rem;
				height: 3rem;
				cursor: pointer;
				margin: 1rem auto 0 auto;
				line-height: 3rem;
				border-radius: 0.3rem;
				// background: var(--bgr2);
                background: #359d78;
				color: #FFFFFF;
				font-family: 'Proxima Nova Condensed';
				font-size: 1.7rem;
				font-weight: 900;
				text-transform: uppercase;
			}
		}
	}
}