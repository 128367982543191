@import '../../global.scss';
.map {
	height: 28.125rem;
	@include mp {
		width: 100%;
		height: 37.2rem;
	}
}
.address-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: var(--wtob);
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	@include mp {
		flex-direction: column;
	}
	.address-sm-inst {
		background-image: url('../assets/images/inst.png');
		width: 2.875rem;
		height: 2.875rem;
		background-repeat: no-repeat;
		background-size: cover;
		margin-left: 15px;
		margin-top: 0px;
	}
	.address-sm-fb {
		background-image: url('../assets/images/fb.png');
		width: 2.875rem;
		height: 2.875rem;
		background-repeat: no-repeat;
		background-size: cover;
		margin-left: 1rem;
		margin-top: 0px;
	}
	.address-sm-telegram {
		background-image: url('../assets/images/telegram.png');
		width: 2.875rem;
		height: 2.875rem;
		background-repeat: no-repeat;
		background-size: cover;
		margin-left: 1rem;
		margin-top: 0px;
	}
	.address-sm-viber {
		background-image: url('../assets/images/viber.png');
		width: 2.875rem;
		height: 2.875rem;
		background-repeat: no-repeat;
		background-size: cover;
		margin-left: 1rem;
		margin-top: 0px;
	}

	.address-content-left {
		font-size: 2rem;
		line-height: 2.375rem;
		width: 55%;
		div {
			margin-top: 1.5625rem;
		}
		@include mp {display: none;}
		.address-sm-row {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 0.8rem;
		}
	}
	.address-content-right {
		width: 40%;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		color: var(--c);		
		@include mp {
			width: 100%;
		}
		.address-item {
			margin-top: 1.75rem;
			font-size: 1.5rem;
			line-height: 1.75rem;
			color: var(--yel1tob);
			display: flex;
			flex-direction: row;
			@include mp {
				margin-top: 3rem;
				font-size: 2.6rem;
				line-height: 3rem;
			}
			.address-item-horse {
				background-image: url('../assets/images/horse.svg');
				height: 2.2187rem;
				width: 1.625rem;
				background-size: contain;
				background-repeat: no-repeat;
				margin-right: 0.28175rem;
				@include mp {
					height: 4.6rem;
					width: 3.6rem;
					margin-right: 2rem;
				}
			}
			.address-item-data {
				display: flex;
				flex-direction: column;
				.address-item-name{
				}
				.address-item-address {
				}
			}
		}
	}
	.address-mobile {
		@include mt {
			display: none;
		}
		@include md {
			display: none;
		}
		margin-top: 5rem;
		display: flex;
		flex-direction: column;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;

		color: #F1F087;
		a {
			color: #F1F087;
			text-decoration:  none;
		}
		.address-phone {
			display: flex;
			flex-direction: row;
			align-items: center;
			svg {
				margin-right: 1rem;
				width: 4rem;
				height: 5.4rem;
				path {
					stroke: #F1F087;
				}
					
			}
		}
		.address-mobile-email {
			margin-top: 3rem;
		}
		.address-content-social {
			margin-top: 2rem;
			display: flex;
			align-items: flex-end;
		}
	}
	.address-item-phone {
		font-size: 1.25rem;
	    line-height: 1.5rem;
		color: var(--wtog);
		@include mp {
			font-size: 2.25rem;
			line-height: 2.5rem;
		}
	}
}