@import '../../global.scss';
.cities {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	a {
		// width: 33.3333%;
		text-decoration: none;
		.city-container {
			width: 100%;
			height: 24rem;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			.city {
				width: 95%;
				height: 95%;
				background: #9EDA93;
				border-radius: 10px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				.city-name {
					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 2rem;
					line-height: 2.34375px;
					color: var(--bg);
					min-height: 30%;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
				}
			}
			.city:hover {
				width: 100%;
				height: 100%;
			}
		}
	}
}
.vacancies {
	margin-top: 2rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	.vacancy {
		margin-left: 1rem;
		margin-right: 1rem;
		white-space: nowrap;
		width: 30%;
		height: 6.53125rem;
		margin-bottom: 2.6875rem;
		background: #9EDA93;
		border-radius: 10px;
		cursor: pointer;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 2rem;
		line-height: 2.34375px;
		color: var(--bg);
		// color: #000000;
		@include mp {
			width: 47%;
			height: 5rem;
			margin-bottom: 1.2rem;
		}
	}
	.vacancy:hover {
		background: #F1F087;
	}

}
.subvacancies {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	.subvacancy {
		width: min-content;
		height: 6.53125rem;
		margin-bottom: 2.6875rem;
		background: #9EDA93;
		border-radius: 10px;
		cursor: pointer;
		white-space: nowrap;
		padding-left: 4.53125rem;
		padding-right: 4.53125rem;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 2rem;
		line-height: 2.34375px;
		color: var(--bg);
		// color: #000000;
		@include mp {
			height: 5rem;
			margin-bottom: 1.2rem;
		}

	}
	.subvacancy:hover {
		background: var(--fgryeli);
	}
}

.vacancy-description {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 1rem;
	.vacancy-title {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 2.25rem;
		line-height: 2.625rem;

		text-transform: uppercase;
		color: #9EDA93;
		@include mp {
			font-size: 3.6rem;
			line-height: 4.2rem;
		}
	}
	.vacacy-text {
		text-align: left;
		margin-top: 2.1875rem;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 1.5rem;
		line-height: 1.75rem;
		// color: #DDEA73;
		color: var(--yeltob);
		@include mp {
			font-size: 2.6rem;
			line-height: 3rem;
		}
		a {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 1.5rem;
			text-decoration: none;
			color: #1DBD81;
		}
	}
}

.store-image {
	height: 75%;
    width: 100%;
    background-size: cover;
	background-repeat: no-repeat;
	border-radius: 10px;
}
.store0 {
	@extend .store-image;
	background-image: url('../assets/images/store0.png');
}
.store1 {
	@extend .store-image;
	background-image: url('../assets/images/store1.png');
}
.store2 {
	@extend .store-image;
	background-image: url('../assets/images/store2.png');
}