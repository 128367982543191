@import '../../global.scss';
.menu {
	margin-top: 1.3125rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	// background: linear-gradient(0.95deg, #AFC04E 14.62%, #D5E670 45.92%, #DDEA73 87.42%);
	background: var(--bggryel);
	border-radius: 4px;
	padding: 0px 1.5rem;
	z-index: 2;
	@include mp {
		display: none;
	}

	a {
		text-decoration: none;
	}
	.menu-item {
		cursor: pointer;
		padding: 1.25rem 0.5rem;
		border-radius: 10px;
		color: black;
		font-size: 0.93rem;
		font-weight: 500;
		// padding-left: 1rem;
		// padding-right: 1rem;
	}
	.menu-item:hover {
		background: var(--fgryel);
		color: var(--c);
	}
}
.menu-mobile {
	transition: width 0.6s;
	transition-timing-function: cubic-bezier(.64,.17,.9,.25);
	position: fixed;
	top: 0rem;
	left: 0rem;
	width: 0rem;
	height: 100vh;
	z-index: 200;
	background-color: var(--fgryeli);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
	padding-top: 3rem;
	padding-bottom: 3rem;
	@include mt {
		display: none;
	}
	@include md {
		display: none;
	}
	&.menu-mobile-show {
		width: 55vw;
		padding-left: 3rem;
		padding-right: 3rem;
	}
	.mobile-menu-line {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
	a {
		text-decoration: none;
		width: 100%;
		white-space: nowrap;
		.mobile-menu-item {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 2.6rem;
			line-height: 3rem;
			text-align: center;
			text-decoration: none;
	
			color: #000000;
		}
	}
	.menu-social {
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: 6rem;
		width: 50%;
		.menu-inst {
			background-image: url('../assets/images/inst.png');
			width: 5.4rem;
			height: 5.4rem;
			background-repeat: no-repeat;
			background-size: cover;
		}
		.menu-fb {
			background-image: url('../assets/images/fb.png');
			width: 5.4rem;
			height: 5.4rem;
			background-repeat: no-repeat;
			background-size: cover;
			margin-left: 2rem;
		}
	}

}